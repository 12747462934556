import React, { useState, useEffect } from 'react';
import Layout from '../../Layout';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getServiceUrl } from '../../config';
import PatientBasicDetails from './PatientBasicDetails';
import Loader from '../../components/CustomUI/Loader';
import { FaCheckCircle } from 'react-icons/fa';
import { BiFontSize, BiUnderline } from 'react-icons/bi';


const apiBaseUrl = getServiceUrl();

export default function DiseaseDetails() {
  const patientData = localStorage.getItem("patientData") ? JSON.parse(localStorage.getItem("patientData")) : [];
  const { patientguid, diseaseName } = useParams();
  const [diseaseDetails, setDiseaseDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('overview');
  const [noDetailsFound, setNoDetailsFound] = useState(false);
  const [selectedDisease, setSelectedDisease] = useState(false);

  const fetchDiseaseDetails = async () => {
    if (!patientguid || !diseaseName) {
      console.error("Missing patientguid or diseaseName in URL parameters.");
      return;
    }

    const formBody = {
      name: diseaseName
    };
    const config = {
      headers: {
        'rdxtoken': localStorage.getItem('tokenId'),
      },
    };

    try {
      const response = await axios.post(apiBaseUrl + 'doctors/diseaseinfo', formBody, config);
      const data = response.data[0];

      if (!data) {
        setNoDetailsFound(true);
        setLoading(false);
        return;
      }

      if (data.synonyms) {
        try {
          data.synonyms = JSON.parse(data.synonyms.replace(/'/g, '"'));
        } catch (error) {
          console.error("Error parsing synonyms field:", error);
        }
      }

      setDiseaseDetails(data);
      setNoDetailsFound(false);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching disease details:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDiseaseDetails();
  }, [patientguid, diseaseName]);
 
const handleSelectedDisease=()=>{
setSelectedDisease(!selectedDisease)
}
  return (
    <Layout patientguid={patientguid} diseaseName={diseaseName}>
      {patientData ? <PatientBasicDetails patientData={patientData} /> : null}
     {
      loading?<Loader/>:
      <div className="bg-white my-8 rounded-xl border border-gray-300 shadow-md p-6">
     { noDetailsFound?
  <div className="text-center text-gray-500 py-4">No details found for {diseaseName.toUpperCase()}</div>:
      <React.Fragment>
      <div className="flex justify-end mb-4">
            <button
              className={`px-4 py-2 ${selectedDisease ? 'bg-blue-900' : 'bg-gray-500'} text-white rounded-md flex items-center`}
              onClick={() => handleSelectedDisease()}
            >
              {selectedDisease ? (
                <>
                  <FaCheckCircle className="mr-2" />
                  Selected Disease
                </>
              ) : (
                'SELECT THIS DISEASE'
              )}
            </button>
            <button 
            className="ml-4 px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-green-600"
            >
              GET Test Details
            </button>
          </div>
        {/* Tabs Navigation */}
        <h2 className="text-2xl font-bold mb-4 text-dark" style={{textDecoration:'underline'}}>{diseaseName.toUpperCase()}</h2>
        <div style={{fontSize:13, textAlign:'justify'}}>
          {diseaseDetails.clinical_description}
        </div>
      <div className="flex border-b border-blue-900 mb-4">
        <button
          className={`py-2 px-4 font-semibold text-lg ${activeTab === 'overview' ? 'border-b-2 border-primary text-primary' : 'text-gray-600'}`}
          onClick={() => setActiveTab('overview')}
        >
          Overview
        </button>
        <button
          className={`py-2 px-4 font-semibold text-lg ${activeTab === 'details' ? 'border-b-2 border-primary text-primary' : 'text-gray-600'}`}
          onClick={() => setActiveTab('details')}
        >
          Details
        </button>
        <button
          className={`py-2 px-4 font-semibold text-lg ${activeTab === 'diagnosis' ? 'border-b-2 border-primary text-primary' : 'text-gray-600'}`}
          onClick={() => setActiveTab('diagnosis')}
        >
          Diagnosis
        </button>
        <button
          className={`py-2 px-4 font-semibold text-lg ${activeTab === 'management' ? 'border-b-2 border-primary text-primary' : 'text-gray-600'}`}
          onClick={() => setActiveTab('management')}
        >
          Management
        </button>
      </div>

      {/* Tabs Content */}
      <div className="pt-4">
        {activeTab === 'overview' && (
          <div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">Orpha ID:</h3>
              <p>{diseaseDetails.orpha_id}</p>
            </div>
            {/* <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">Name:</h3>
              <p>{diseaseDetails.name}</p>
            </div> */}
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">Prevalence:</h3>
              <p>{diseaseDetails.prevalence}</p>
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">Inheritance:</h3>
              <p>{diseaseDetails.inheritance}</p>
            </div>
          </div>
        )}

        {activeTab === 'details' && (
          <div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">Synonyms:</h3>
              <p>{diseaseDetails.synonyms ? diseaseDetails?.synonyms : 'N/A'}</p>
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">Age of Onset:</h3>
              <p>{diseaseDetails.age_of_onset}</p>
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">IDs A:</h3>
              <p>{diseaseDetails.ids_a}</p>
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">IDs B:</h3>
              <p>{diseaseDetails.ids_b}</p>
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">OMIM:</h3>
              <p>{diseaseDetails.omim}</p>
            </div>
          </div>
        )}

        {activeTab === 'diagnosis' && (
          <div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">Epidemiology:</h3>
              <p>{diseaseDetails.epidemiology}</p>
            </div>
            {/* <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">Clinical Description:</h3>
              <p>{diseaseDetails.clinical_description}</p>
            </div> */}
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">Etiology:</h3>
              <p>{diseaseDetails.etiology}</p>
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">Differential Diagnosis:</h3>
              <p>{diseaseDetails.differential_diagnosis}</p>
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">Antenatal Diagnosis:</h3>
              <p>{diseaseDetails.antenatal_diagnosis}</p>
            </div>
          </div>
        )}

        {activeTab === 'management' && (
          <div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">Genetic Counseling:</h3>
              <p>{diseaseDetails.genetic_counseling}</p>
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">Management and Treatment:</h3>
              <p>{diseaseDetails.management_and_treatment}</p>
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-dark">Prognosis:</h3>
              <p>{diseaseDetails.prognosis}</p>
            </div>
          </div>
        )}
      </div>
      </React.Fragment>
}
      </div>
     }
    </Layout>
  );
}

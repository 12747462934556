import React, { useState, useMemo } from 'react';
import { FaSearch } from 'react-icons/fa';
import { toast } from 'react-hot-toast';

const thclass = 'text-left text-sm font-medium py-3 px-2 whitespace-nowrap';
const tdclass = 'text-left text-sm py-4 px-2 whitespace-nowrap';

// Utility function for calculating BMI
const calculateBMI = (height, weight) => {
  if (height && weight) {
    const heightInMeters = height / 100; // converting height to meters
    return (weight / (heightInMeters * heightInMeters)).toFixed(2);
  }
  return '-'; // Return '-' if height or weight is not provided
};

// Main component
export function PatientTable({ data, functions }) {
  const [sortColumn, setSortColumn] = useState('created_at');
  const [sortOrder, setSortOrder] = useState('desc');
  const [filters, setFilters] = useState({ search: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);

  // Sorting function
  const sortedData = useMemo(() => {
    debugger
    return [...data].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }, [data, sortColumn, sortOrder]);

  // Filter and Pagination logic
  const filteredData = useMemo(() => {
    return sortedData.filter(item =>
      item.firstname.toLowerCase().includes(filters.search.toLowerCase())
    );
  }, [sortedData, filters.search]);

  const indexOfLast = currentPage * recordsPerPage;
  const indexOfFirst = indexOfLast - recordsPerPage;
  const paginatedData = filteredData.slice(indexOfFirst, indexOfLast);

  // Handle sorting
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle next/previous page
  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / recordsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4 overflow-x-auto">
      {/* Search Filter */}
      <div className="mb-4 flex justify-between items-center">
        <div className="relative flex-1">
          <input
            type="text"
            placeholder="Search by name..."
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
            className="border rounded pl-10 pr-4 py-2 w-full"
          />
          <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
        </div>
      </div>

      {/* Table */}
      <table className="table-auto w-full bg-gray-100 border border-gray-300 rounded-lg">
        <thead className="bg-blue-900 text-white rounded-t-lg">
          <tr>
            <th className={thclass}>Patient</th>
            <th className={thclass} onClick={() => handleSort('createddate')}>
              Created At {sortColumn === 'createddate' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
            </th>
            <th className={thclass} onClick={() => handleSort('gender')}>
              Gender {sortColumn === 'gender' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
            </th>
            <th className={thclass} onClick={() => handleSort('height')}>
              BMI {sortColumn === 'height' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
            </th>
            <th className={thclass} onClick={() => handleSort('age')}>
              Age {sortColumn === 'age' ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((item) => (
            <tr
              key={item.id}
              className="border-b border-gray-200 hover:bg-gray-50 cursor-pointer"
              onClick={() => functions.preview(item.patientguid)}
            >
              <td className={tdclass}>
                <div className="flex gap-4 items-center">
                  <div>
                    <h4 className="text-sm font-medium">{item.firstname}</h4>
                  </div>
                </div>
              </td>
              <td className={tdclass}>{item.createddate}</td>
              <td className={tdclass}>
                <span
                  className={`py-1 px-4 ${
                    item.gender === 'Male'
                      ? 'bg-blue-200 text-blue-800'
                      : 'bg-orange-200 text-orange-800'
                  } rounded-full text-xs`}
                >
                  {item.gender}
                </span>
              </td>
              <td className={tdclass}>{calculateBMI(item.height, item.weight)}</td>
              <td className={tdclass}>{item.age}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination and Records Per Page */}
      <div className="flex justify-between items-center mt-4">
        {/* Pagination Numbers */}
        <div className="flex gap-2 items-center">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="border rounded p-2 bg-white text-blue-500 disabled:opacity-50"
          >
            &laquo; Prev
          </button>
          {Array.from({ length: Math.ceil(filteredData.length / recordsPerPage) }, (_, i) => (
            <button
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              className={`border rounded p-2 ${currentPage === i + 1 ? 'bg-blue-900 text-white' : 'bg-white text-blue-500'}`}
            >
              {i + 1}
            </button>
          ))}
          <button
            onClick={handleNextPage}
            disabled={currentPage === Math.ceil(filteredData.length / recordsPerPage)}
            className="border rounded p-2 bg-white text-blue-500 disabled:opacity-50"
          >
            Next &raquo;
          </button>
        </div>

        {/* Records Per Page Dropdown */}
        <div className="flex items-center">
          <select
            value={recordsPerPage}
            onChange={(e) => setRecordsPerPage(Number(e.target.value))}
            className="border rounded p-2 bg-white"
          >
            <option value={5}>5 records per page</option>
            <option value={10}>10 records per page</option>
            <option value={50}>50 records per page</option>
          </select>
        </div>
      </div>
    </div>
  );
}

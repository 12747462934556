// src/components/Charts/PieChart.js

import React from 'react';
import Chart from 'react-apexcharts';

const PieChart = ({ data }) => {
  const chartOptions = {
    chart: {
      type: 'pie',
      height: 250,
    },
    labels: data.map(item => item.value),
    colors: ['#F69600', '#3BB27B', '#F4A460', '#8B0000', '#123569'],
   // colors: ['#F69600 ', '#3BB27B ', '#F4A460 ', '#8B0000 ', '#123569 '],
    legend: {
      show: false,
      position: 'bottom',
      labels: {
        colors: '#333', // Subtle legend color
      },
     },
  };

  const chartSeries = data.map(item => item.count);

  return (
    <Chart
      options={chartOptions}
      series={chartSeries}
      type="pie"
      width="100%"
    />
  );
};

export default PieChart;

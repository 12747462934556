/**
 * This method will return the hostname depending upon the current server mode. comment
 * @param {ServerMode : localhost, beta, alpha, live} serveMode
 */

export function getServiceUrl() {
    //return "http://localhost:3001/" ;
    return "http://13.202.164.161:3001/" ;
    }

export function getAWSUrl() {
  
}

export function getWebsiteUrl() {
   
}
export function getUserPermision(permissions, pageKey) {
    if (permissions !== null) {
        let userPermission = permissions.filter(x => {
            return x.pageKey.toLowerCase() === pageKey.toLowerCase();
        });
        if (userPermission.length === 0) {
            return null;
        } else {
            return userPermission[0].rights;
        }
    } else {
        return null;
    }
}

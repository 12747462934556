import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const TreeChart = ({ data }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    const width = 800;
    const height = 600;
    const margin = { top: 20, right: 120, bottom: 20, left: 120 };

    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const treeLayout = d3.tree().size([height - margin.top - margin.bottom, width - margin.left - margin.right]);

    // Prepare the tree data
    const root = d3.hierarchy(data);
    treeLayout(root);

    // Links
    g.selectAll('path.link')
      .data(root.descendants().slice(1))
      .enter().append('path')
      .attr('class', 'link')
      .attr('d', d => `
        M${d.y},${d.x}
        C${d.y + (d.parent.y - d.y) / 2},${d.x}
          ${d.parent.y - (d.parent.y - d.y) / 2},${d.parent.x}
          ${d.parent.y},${d.parent.x}
      `);

    // Nodes
    const node = g.selectAll('g.node')
      .data(root.descendants())
      .enter().append('g')
      .attr('class', 'node')
      .attr('transform', d => `translate(${d.y},${d.x})`);

    node.append('circle')
      .attr('r', 5);

    node.append('text')
      .attr('dy', 3)
      .attr('x', d => d.children ? -10 : 10)
      .style('text-anchor', d => d.children ? 'end' : 'start')
      .text(d => d.data.name);

  }, [data]);

  return (
    <svg ref={svgRef}></svg>
  );
};

export default TreeChart;

import React from 'react';

const NodataBox=({mainText, subText})=>{
return<div className="no-data flex flex-col items-center justify-center h-48 bg-gray-100 rounded-md border border-gray-300 p-4">
<svg
  xmlns="http://www.w3.org/2000/svg"
  className="h-12 w-12 text-gray-400 mb-3"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    d="M9 17v2a3 3 0 003 3h6a3 3 0 003-3v-2M9 12v-2m0-4V7m0-4h.01M9 5V5.01M9 12V12.01M15 9v2m0-2h.01M15 5V5.01M15 12V12.01"
  />
</svg>
<p className="text-gray-500 text-lg font-medium">
  {mainText}
</p>
<p className="text-gray-400 text-sm">
 {subText}
</p>
</div>
}

export default NodataBox;
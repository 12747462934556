// PedigreeChart.js
import React from 'react';
import TreeChart from './TreeChart';
// Custom styles for the chart

const data = {
  name: "Root",
  children: [
    {
      name: "Child 1",
      children: [
        { name: "Grandchild 1" },
        { name: "Grandchild 2" }
      ]
    },
    {
      name: "Child 2"
    }
  ]
};

const PedigreeChart = ({ patientData, patientguid, onDataUpdate }) => {


  return (
    <div style={{ height: '200px', textAlign:'center', fontSize:20 }}>
      <h2>This feature is coming soon.</h2>     
    </div>
  );
};

export default PedigreeChart;

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

export const PublicRoute = ({ element: Element, ...rest }) => {
  try {
  const location = useLocation();
  const token = localStorage.getItem('tokenId');
 
  if(token)
  {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp > currentTime) {
      return <Navigate to="/dashboard" replace state={{ from: location }} />;
    }
  }

  // If the user is not authenticated, render the public page
  return <Element {...rest} />;

} catch (error) {
  // Handle invalid token
  return <Element {...rest} />
}
};

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../Layout';
import { patientTab } from '../../components/Datas';
import PedigreeChart from './PedigreeChart';
import Symptoms from './Symptoms';
import axios from 'axios';
import { getServiceUrl } from '../../config';
import FamilyInfo from './FamilyInfo';
import MedicalRecord from './MedicalRecord';
import Summary from './Summary';
import { getS3Details } from '../../utility';
import PatientBasicDetails from './PatientBasicDetails';
import { confirmAlert } from 'react-confirm-alert';
import { jwtDecode } from 'jwt-decode';

const apiBaseUrl = getServiceUrl();

const PatientProfile = () => {
  const { patientguid } = useParams();
  const navigate = useNavigate();
  const [patientData, setPatientData] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const isLastPage = activeTab === patientTab.length;
  const [selectedData, setSelectedData] = useState({});
  const [isSymptomsCompleted, setIsSymptomsCompleted] = useState(false); // Track if Symptoms criteria are met

  const showAlert = (message, type) => {
    const overlayStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1000,
        fontFamily: "'Roboto', sans-serif", // Similar font
    };

    const panelStyle = {
        backgroundColor: '#ffffff',
        color: type === 'error' ? '#ff4d4f' : '#5b9bd5',
        borderRadius: '10px',
        padding: '30px',
        width: '90%',
        maxWidth: '400px',
        textAlign: 'center',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
        border: `1px solid ${type === 'error' ? '#ff4d4f' : '#5b9bd5'}`,
    };

    const buttonStyle = {
        backgroundColor: type === 'error' ? '#ff4d4f' : '#5b9bd5',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '10px 20px',
        cursor: 'pointer',
        fontSize: '14px',
        marginTop: '20px',
        transition: 'background-color 0.3s ease',
    };

    const buttonHoverStyle = {
        backgroundColor: type === 'error' ? '#d94444' : '#4a87c0', // Darken on hover
    };

    confirmAlert({
        customUI: ({ onClose }) => {
          return (
                <div style={overlayStyle}>
                    <div style={panelStyle}>
                        <p>{message}</p>
                        <button
                            style={buttonStyle}
                            onClick={onClose}
>
                            Close
                        </button>
                    </div>
                </div>
            );
        },
        closeOnClickOutside: true,
    });
};
  const getPatientDetails = async (patientguid) => {
   try {
      const config = {
        headers: {
          "rdxtoken": localStorage.getItem("tokenId"),
          "patientguid": patientguid,
        }
      };
      const response = await axios.get(`${apiBaseUrl}patient/patientmedicalhistory`, config);
      return response.data[0];
    } catch (error) {
      console.error("Error fetching patient details:", error.response ? error.response.data : error.message);
      return null;
    }
  };

  const fetchData = useCallback(async () => {
    if (patientguid) {
      const data = await getPatientDetails(patientguid);
      if (data) {
        setPatientData(data);
        localStorage.setItem("patientData",JSON.stringify(data))
        // Check if Symptoms criteria are met
        if (data.symptomdetails && data.symptomdetails.length >= 3) {
          setIsSymptomsCompleted(true);
        }
      }
    } else {
      navigate('/patients');
    }
  }, [patientguid, navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDataUpdate = (data) => {
    setSelectedData(data);
    // Update Symptoms completion status
    if (data.symptomsData && data.symptomsData.length >= 3) {
      setIsSymptomsCompleted(true);
    } else {
      setIsSymptomsCompleted(false);
    }
  };

  const tabPanel = () => {
    switch (activeTab) {
      case 1:
        return <FamilyInfo patientData={patientData} patientguid={patientguid} onDataUpdate={handleDataUpdate} />;
      case 2:
        return <PedigreeChart patientData={patientData} patientguid={patientguid} onDataUpdate={handleDataUpdate} />;
      case 3:
        return <MedicalRecord patientData={patientData} patientguid={patientguid} onDataUpdate={handleDataUpdate} />;
      case 4:
        return <Symptoms patientData={patientData} patientguid={patientguid} onDataUpdate={handleDataUpdate} />;
      case 5:
        return <Summary patientData={patientData} patientguid={patientguid} />;
      default:
        return null;
    }
  };

  const upadteMedicalFiles = async (formData) => {
    try {
      let prepareFileDetailsForDB=[];
      const token = localStorage.getItem('tokenId');
      const decoded = jwtDecode(token);
      const s3 = getS3Details();
      if(formData.length>0)
        {
          const newFilesToUpload=formData.filter(x=>x.file);
          const existingFilesToUpload=formData.filter(item => !newFilesToUpload.includes(item));
          if(newFilesToUpload.length)
            {
              const uploadPromises = newFilesToUpload
        .filter(file => file.file.type === 'application/pdf') // Filter PDFs only
        .map((file) => {
          const timestamp = Date.now(); // Get current timestamp
          const fileNameWithTimestamp = `${file.name.split('.')[0]}_${timestamp}.${file.name.split('.').pop()}`; // Add timestamp to the file name
          
          const params = {
            Bucket: 'emrehr-bucket',
            Key: `medicalfiles/${decoded.userGuid}/${patientguid}/${fileNameWithTimestamp}`, // Modify Key with the new unique file name
            Body: file.file,
            ContentType: file.file.type,
          };
  
          return s3.upload(params).promise()
            .then(uploadResult => ({ fileName: fileNameWithTimestamp, fileUrl: uploadResult.Location })); // Return both file name with timestamp and URL
        });
        const uploadResults = await Promise.all(uploadPromises);
        // Initialize the prepareFileDetailsForDB array with mapped uploadResults
        prepareFileDetailsForDB = uploadResults.map(file => ({
          filename: file.fileName,  // Original file name with timestamp
          fileurl: file.fileUrl     // S3 URL
        }));
        
          }
                  // Add existingFilesToUpload items to prepareFileDetailsForDB
                  prepareFileDetailsForDB = prepareFileDetailsForDB.concat(
                    existingFilesToUpload.map(item => ({
                      filename: item.name,
                      fileurl: item.url
                    }))
                  );
      }

       

       
        const formBody = {
          filedetails: prepareFileDetailsForDB
        };
  
        const config = {
          headers: {
            "rdxtoken": localStorage.getItem("tokenId"),
            "patientguid": patientguid
          }
        };
  
        await axios.post(apiBaseUrl + 'doctors/insertmedicalfile', formBody, config);
        return true;
      
    } catch (error) {
      console.error("Error uploading medical files:", error.response ? error.response.data : error.message);
      return null;
    }
  };
  
  

  const upadteFamilyInfo = async (formData) => {
    try {
      const config = {
        headers: {
          "rdxtoken": localStorage.getItem("tokenId"),
          "patientguid": patientguid
        }
      };
      await axios.post(apiBaseUrl + 'doctors/insertfamily', formData, config);
    } catch (error) {
      console.error("Error updating family info:", error.response ? error.response.data : error.message);
      return null;
    }
  };

  const updateSymptomstoDB = async () => {
    try {
      //const result = selectedData.symptomsData.map(item => item.hpoid);
      const symptomsDetails = selectedData.symptomsData.map((item) => ({
        source: item.source==="Report"?"Report":"Manual",
        hpoid: item.hpoid,
        sourcefile:null,
        startdate:item.startDate,
        enddate:item.endDate        
      }));
      const formBody = {
        patientguid: patientguid,
        hpoids: symptomsDetails,
      };
      const config = {
        headers: {
          rdxtoken: localStorage.getItem('tokenId'),
        },
      };
      await axios.post(apiBaseUrl + 'doctors/savepatienthpoids', formBody, config);
    } catch (error) {
      console.error('Error updating symptoms:', error);
    }
  };

  const handleNextClick = async() => {
    const data = await getPatientDetails(patientguid);
    if (data) {
      setPatientData(data);
      localStorage.setItem("patientData",JSON.stringify(data))
     }
    switch (activeTab) {
      case 1:
        if (selectedData.familyInfo) {
          upadteFamilyInfo(selectedData.familyInfo);
        }
        setActiveTab(prevTab => (prevTab % patientTab.length) + 1);
        break;

      case 2:
        setActiveTab(prevTab => (prevTab % patientTab.length) + 1);
        break;

      case 3:
        if (selectedData.medicalFilesData) {
          const shouldContinue=await upadteMedicalFiles(selectedData.medicalFilesData)
          if(shouldContinue)
            {
             setActiveTab(prevTab => (prevTab % patientTab.length) + 1);
            }
        }
        else
        {
        setActiveTab(prevTab => (prevTab % patientTab.length) + 1);
        }
        break;

      case 4:
        if (isSymptomsCompleted) {
          updateSymptomstoDB();
          setActiveTab(prevTab => (prevTab % patientTab.length) + 1);
        } else {
          showAlert('Please select at least 3 symptoms before proceeding.', 'error');
         }
        break;

      case 5:
        setActiveTab(prevTab => (prevTab % patientTab.length) + 1);
        break;

      default:
        return null;
    }
  };

  const handlePreviousClick = async() => {
    const data = await getPatientDetails(patientguid);
    if (data) {
      setPatientData(data);
      localStorage.setItem("patientData",JSON.stringify(data))
     }
    setActiveTab(prevTab => prevTab === 1 ? patientTab.length : prevTab - 1);
  };

  const handleTabClick = async(tabId) => {
   const data = await getPatientDetails(patientguid);
    if (data) {
      setPatientData(data);
      localStorage.setItem("patientData",JSON.stringify(data))
      // Check if Symptoms criteria are met
      if (data.symptomdetails && data.symptomdetails.length >= 3) {
        setIsSymptomsCompleted(true);
      }
    }

  // Prevent switching to Summary tab if Symptoms criteria are not met
  if (tabId === 5 && !isSymptomsCompleted) {
    showAlert('Please select at least 3 symptoms before proceeding.', 'error');
    return;
  }

  setActiveTab(tabId);
  };

  return (
    <Layout patientguid={patientguid}>
    {
       patientData?<PatientBasicDetails patientData ={ patientData }/>:null
    }
      
      <div className="grid grid-cols-12 gap-6 my-8 items-start">
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="100"
          data-aos-offset="200"
          className="col-span-12 flex-colo gap-6 lg:col-span-4 bg-white rounded-xl border-[1px] border-border p-6 lg:sticky top-28"
        >
          {/* Tabs */}
          <div className="flex-colo gap-3 px-2 xl:px-12 w-full">
            {patientTab.map((tab) => (
              <button
                onClick={() => handleTabClick(tab.id)}
                key={tab.id}
                className={`
                  ${activeTab === tab.id ? 'bg-text text-subMain' : 'bg-dry text-main hover:bg-text hover:text-subMain'}
                  text-xs gap-4 flex items-center w-full p-4 rounded`}
              >
                <tab.icon className="text-lg" /> {tab.title}
              </button>
            ))}
          </div>
        </div>
        {/* Tab Panel */}
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="100"
          data-aos-offset="200"
          className="col-span-12 lg:col-span-8 bg-white rounded-xl border-[1px] border-border p-6"
        >
          {tabPanel()}
          <div className="flex justify-between mt-4">
            <button
              onClick={handlePreviousClick}
              className={`bg-gray-500 text-white px-4 py-2 rounded ${activeTab === 1 && 'invisible'}`}
            >
              Previous
            </button>
            {isLastPage ? (
              <button
                onClick={() => navigate(`/patients/diseases/${patientData.patientguid}`, {
                  patientData: { patientData }
                })}
                className="bg-yellow-500 text-white px-4 py-2 rounded"
              >
                 Get a Differential Diagnosis
              </button>
            ) : (
              <button
                onClick={handleNextClick}
                className="bg-blue-900 text-white px-4 py-2 rounded"
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PatientProfile;

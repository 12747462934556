import React from 'react';
import { Link, useLocation} from 'react-router-dom';

function Sidebar({ patientguid, diseaseName }) {
  const location = useLocation();
  const currentPath = location.pathname;

  let permissions = [];
  if (patientguid) {
    permissions.push(
     {
        title: "Patients",
        path: "/patients",
      },
      // {
      //   title: "Summary",
      //   path: `/patients/dashboard/${patientguid}`,
      // },
      {
        title: "Disease",
        path: `/patients/diseases/${patientguid}`,
      },
      {
        title: "Genotype",
        path: `/patients/genotype/${patientguid}`,
      },
      // {
      //   title: "Test+",
      //   path: `/patients/tests/${patientguid}`,
      // }
    );
  } else {
    permissions.push(
      {
        title: "Dashboard",
        path: "/dashboard",
      },
      {
        title: "Patients",
        path: "/patients",
      },
      {
        title: "Test Booking",
        path: "/testbooking",
      }
    );
  }

  const isActive = (path) => {
   if(path===currentPath || path+'/'+patientguid===currentPath)
      return true;
    return false
  };

  return (
    <div className="bg-white xl:shadow-lg py-6 px-4 xl:h-screen w-full border-r border-border">
       <img src="/images/rdxlogo.png" alt="logo" className="w-48 h-16 object-contain" />
      <div className="flex-colo gap-2 mt-12">
        {permissions.map((item, index) => (
          <Link
            to={item.path}
            key={index}
            className={`flex gap-4 transitions group items-center w-full p-4 rounded-lg ${
              isActive(item.path) ? 'bg-text' : 'hover:bg-text'
            }`}
          >
            {/* <item.icon
              className={`text-xl text-subMain
            `}
            /> */}
            <p
              className={`text-sm font-medium group-hover:text-subMain ${
                isActive(item.path) ? 'text-subMain' : 'text-gray-500'
              }`}
            >
              {item.title}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Sidebar;

import React from 'react';
import { MenuSelect } from '../components/Form';
import { TbUser } from 'react-icons/tb';
import { AiOutlinePoweroff } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { BiMenu } from 'react-icons/bi';
import MenuDrawer from '../components/Drawer/MenuDrawer';
import * as actionCreators from '../store/actions/index';
import { connect } from 'react-redux';

const Header = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  // toggle drawer
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const navigate = useNavigate();
  const DropDown1 = [
    // {
    //   title: 'Profile',
    //   icon: TbUser,
    //   onClick: () => {
    //     navigate('/settings');
    //   },
    // },
    {
      title: 'Logout',
      icon: AiOutlinePoweroff,
      onClick: () => {
        props.onLogout(navigate)
      },
    },
  ];

  return (
    <>
      {isOpen && <MenuDrawer isOpen={isOpen} toggleDrawer={toggleDrawer} />}

      {/* cmp */}
      <div className="xl:w-5/6 w-full 2xl:max-w-[1640px] bg-dry grid md:grid-cols-2 grid-cols-12 items-center bg-opacity-95 fixed top-0 z-40 xs:px-8 px-2">
        <div className="md:col-span-1 sm:col-span-11 col-span-10 flex gap-4 items-center md:py-0 py-4">
          <button
            onClick={toggleDrawer}
            className="block xl:hidden border text-2xl bg-greyed w-16 md:w-12 h-12 rounded-md flex-colo text-textGray transitions hover:bg-border"
          >
            <BiMenu />
          </button>
          {/* search */}
          {/* <input
            type="text"
            placeholder='Search "Patients"'
            className="md:w-96 w-full h-12 text-sm text-main rounded-md bg-dry border border-border px-4"
          /> */}
        </div>
        <div className="md:col-span-1 sm:col-span-1 col-span-2 items-center justify-end pr-4 md:pr-0">
          <div className="float-right flex gap-4 items-center justify-center">
          <div className="items-center md:flex hidden relative">
  <MenuSelect datas={DropDown1}>
    <div className="flex gap-2 items-center p-4 rounded-lg cursor-pointer transition-colors hover:bg-gray-200" title="Click to access settings">
      <img
        src="/images/user.jpg"
        alt="user"
        className="w-8 border border-border object-cover h-8 rounded-full"
      />
      <p className="text-sm text-textGray font-medium">
        Dr. {localStorage.getItem("userFullName")}
      </p>
      {/* Icon indicating the dropdown */}
      <span className="ml-2 text-gray-500">
        ▼ {/* You can replace this with an icon, e.g., an arrow down icon */}
      </span>
    </div>
  </MenuSelect>

  {/* Tooltip for Dropdown */}
  <div className="absolute top-full left-0 mt-1 w-48 rounded-md bg-gray-800 text-white text-sm hidden group-hover:block">
    <div className="p-2">Access Profile & Logout</div>
  </div>
</div>
</div>
        </div>
      </div>
    </>
  );
}


const mapStateToProps = (state) => ({
  loading: state.login.loading,
});
const mapDispatchToProps = (dispatch) => ({
  onLogout: (navigate) => dispatch(actionCreators.logout(navigate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);


import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

export const PrivateRoute = ({ element: Element, ...rest }) => {
  const location = useLocation();
  const token = localStorage.getItem('tokenId');
  const isAuthenticated = localStorage.getItem('IsAuthentic') === 'true';

  // Check if the token exists
  if (!token) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  // Decode the token and check if it's expired
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      return <Navigate to="/login" replace state={{ from: location }} />;
    }
  } catch (error) {
    // Handle invalid token
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  // Render the element if authenticated
  return isAuthenticated ? (
    <Element {...rest} />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};
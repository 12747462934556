import axios from "axios";
import React, { useState } from 'react';
import { getServiceUrl } from "../../config";
import * as actionTypes from "./actionTypes";
import { jwtDecode } from 'jwt-decode';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const apiBaseUrl = getServiceUrl();

export const authSuccess = (
    tokenId,
    permissions,
    userFullName
) => ({
    type: actionTypes.AUTH_SUCCESS,
    tokenId,
    permissions,
    userFullName
});

export const authFail = (error, history) => {
    localStorage.setItem("passwordError", error);
       return {
           type: actionTypes.AUTH_FAIL,
           error
       };
   };

export const authLogout = () => ({
    type: actionTypes.AUTH_LOGOUT
});

export const logout = (navigate) => dispatch => {
    dispatch(authLogout());
    navigate('/login'); // Use absolute path
};

const showAlert = (message, type) => {
    const overlayStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1000,
        fontFamily: "'Roboto', sans-serif", // Similar font
    };

    const panelStyle = {
        backgroundColor: '#ffffff',
        color: type === 'error' ? '#ff4d4f' : '#5b9bd5',
        borderRadius: '10px',
        padding: '30px',
        width: '90%',
        maxWidth: '400px',
        textAlign: 'center',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
        border: `1px solid ${type === 'error' ? '#ff4d4f' : '#5b9bd5'}`,
    };

    const buttonStyle = {
        backgroundColor: type === 'error' ? '#ff4d4f' : '#5b9bd5',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '10px 20px',
        cursor: 'pointer',
        fontSize: '14px',
        marginTop: '20px',
        transition: 'background-color 0.3s ease',
    };

    const buttonHoverStyle = {
        backgroundColor: type === 'error' ? '#d94444' : '#4a87c0', // Darken on hover
    };

    confirmAlert({
        customUI: ({ onClose }) => {
          return (
                <div style={overlayStyle}>
                    <div style={panelStyle}>
                        <p>{message}</p>
                        <button
                            style={buttonStyle}
                            onClick={onClose}
>
                            Close
                        </button>
                    </div>
                </div>
            );
        },
        closeOnClickOutside: true,
    });
};




export const auth = (formData, navigate) => dispatch => {
    axios
        .post(apiBaseUrl + "login", formData)
        .then(response => {
            if (response.data.token) {
                if(response.data.is_active)
                {
                    const tokenId = response.data.token;
                    const decodedToken = jwtDecode(tokenId);
                    //set user claims from jwt token
                    const userGuid=decodedToken.userGuid;
                    const userEmail=decodedToken.email;
                    const userRole=decodedToken.userRole;
                    const permissions=decodedToken.permissions;
                    const userSalutation=decodedToken.userSalutation;
                    const userFirstName=decodedToken.userFirstName;
                    const userLastName=decodedToken.userLastName;
    
    
                    localStorage.setItem("IsAuthentic", true);
                    localStorage.setItem("tokenId", tokenId);
                    localStorage.setItem("userGuid", userGuid);
                    localStorage.setItem("userRole", userRole);
                    localStorage.setItem("userEmail", userEmail);
                    localStorage.setItem("permissions", JSON.stringify(permissions));
                    localStorage.setItem("userFullName", userSalutation+" "+userFirstName+" "+userLastName);
                    
                    dispatch(authSuccess(
                        tokenId,
                        permissions,
                        userSalutation+" "+userFirstName+" "+userLastName
                    ));
                    navigate('/dashboard'); // Use absolute path
                }
            else
            {
                showAlert('Your account is not active, Please contact the administrator', 'error');
                dispatch(authFail("Invalid credentials", navigate));
            }
            } else {
                showAlert('Invalid credentials', 'error');
               dispatch(authFail("Invalid credentials", navigate));
            }
        })
        .catch(error => {
            showAlert('An error occurred. Please try again later.', 'error');
           dispatch(authFail("An error occurred. Please try again later.", navigate));
        });
};

import * as actionTypes from "../../store/actions/actionTypes";
import { updateObject } from "../../store/utility";
const initialState = {
  tokenId: '',//localStorage.getItem("tokenId")!==undefined?JSON.parse(localStorage.getItem("tokenId")):null,
  permissions: [],//JSON.parse(localStorage.getItem("permissions")),
  userFullName: '',//JSON.parse(localStorage.getItem("userFullName")),
};

const authSuccess = (state, action) => {
  return updateObject(state, {
   tokenId:action.tokenId,
   permissions: action.permissions,
   userFullName:action.userFullName
  });
};

const authFail = (state, action) => {
  localStorage.setItem("tokenId", null);
  localStorage.setItem("permissions", null);
  localStorage.setItem("userFullName", null);
  
  return updateObject(state, {
    tokenId:null,
    permissions: null,
    userFullName:null,
 });
};

const authLogout = (state, action) => {
  localStorage.setItem("IsAuthentic", false);
  localStorage.setItem("tokenId", null);
  localStorage.setItem("userGuid", null);
  localStorage.setItem("userRole", null);
  localStorage.setItem("userEmail", null);
  localStorage.setItem("permissions", []);
  localStorage.setItem("userFullName", null);
  localStorage.setItem("patientData", null);

  
  return updateObject(state, {
    tokenId:null,
    permissions: null,
    userFullName:null,
 });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // case actionTypes.GET_TOKEN:
    //     return getToken(state, action);
  
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);

    case actionTypes.AUTH_FAIL:
        return authFail(state, action);

    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);

    default:
      return state;
  }
};

export default reducer;

  import React, { useState, useEffect } from 'react';
  import Input from '../Input/Input';
  import axios from 'axios';
  import { getServiceUrl } from '../../config';
  import { Button } from '../Form';
  import { useNavigate } from 'react-router-dom';
  import './PersonalInfo.css'; // Assuming you have a separate CSS file for custom styling
  import { ToastContainer, toast } from 'react-toastify'; // Import toast and ToastContainer
  import 'react-toastify/dist/ReactToastify.css'; 
import { confirmAlert } from 'react-confirm-alert';

  const apiBaseUrl = getServiceUrl();

  const initialState = {
    firstname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'First Name',
      },
      value: '',
      label: 'First Name *',
      validation: {
        required: true,
        minLength: 2,
        isAlpha: true,
      },
      valid: false,
      touched: false,
      errorMessage: 'First name should contain only alphabets and be at least 2 characters long.',
    },
    lastname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Last Name',
      },
      value: '',
      label: 'Last Name *',
      validation: {
        required: true,
        minLength: 2,
        isAlpha: true,
      },
      valid: false,
      touched: false,
      errorMessage: 'Last name should contain only alphabets and be at least 2 characters long.',
    },
    dob: {
      elementType: 'input',
      elementConfig: {
        type: 'date',
        placeholder: 'Birth Date',
      },
      value: '',
      label: 'Birth Date *',
      validation: {
        required: true,
        validDate: true,
      },
      valid: false,
      touched: false,
      errorMessage: 'Valid birth date is required (no future date allowed).',
    },
    gender: {
      elementType: 'select',
      elementConfig: {
        options: [], // Options will be populated from the API
      },
      value: '',
      label: 'Gender *',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      errorMessage: 'Please select a gender.',
    },
    height: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        placeholder: 'Height (cm)',
        step: '0.01',
        min: '0',
      },
      value: '',
      label: 'Height',
      validation: {
        required: false,
        isNumeric: true,
        isLogical: true, // Logical height range
      },
      valid: true,
      touched: false,
      errorMessage: 'Height must be a valid number between 50 cm and 300 cm.',
    },
    weight: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        placeholder: 'Weight (Kg)',
        step: '0.01',
        min: '0',
      },
      value: '',
      label: 'Weight',
      validation: {
        required: false,
        isNumeric: true,
        isLogical: true, // Logical weight range
      },
      valid: true,
      touched: false,
      errorMessage: 'Weight must be a valid number between 2 kg and 500 kg.',
    },
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        placeholder: 'Email',
      },
      value: '',
      label: 'Email',
      validation: {
        required: false,
        isEmail: true,
      },
      valid: true,
      touched: false,
      errorMessage: 'Please enter a valid email address.',
    },
    mobile: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Mobile',
      },
      value: '',
      label: 'Mobile',
      validation: {
        required: false,
        isPhoneNumber: false, // Ensure valid mobile number
      },
      valid: true,
      touched: false,
      errorMessage: 'Please enter a valid 10-digit mobile number.',
    },
  };


  const PersonalInfo = () => {
    const navigate = useNavigate();
    const [formDetails, setFormDetails] = useState(initialState);
    const [gender, setGender] = useState([]);
  
    const showAlert = (message, type) => {
      const overlayStyle = {
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1000,
          fontFamily: "'Roboto', sans-serif", // Similar font
      };
  
      const panelStyle = {
          backgroundColor: '#ffffff',
          color: type === 'error' ? '#ff4d4f' : '#5b9bd5',
          borderRadius: '10px',
          padding: '30px',
          width: '90%',
          maxWidth: '400px',
          textAlign: 'center',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
          border: `1px solid ${type === 'error' ? '#ff4d4f' : '#5b9bd5'}`,
      };
  
      const buttonStyle = {
          backgroundColor: type === 'error' ? '#ff4d4f' : '#5b9bd5',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          padding: '10px 20px',
          cursor: 'pointer',
          fontSize: '14px',
          marginTop: '20px',
          transition: 'background-color 0.3s ease',
      };
  
      const buttonHoverStyle = {
          backgroundColor: type === 'error' ? '#d94444' : '#4a87c0', // Darken on hover
      };
  
      confirmAlert({
          customUI: ({ onClose }) => {
            return (
                  <div style={overlayStyle}>
                      <div style={panelStyle}>
                          <p>{message}</p>
                          <button
                              style={buttonStyle}
                              onClick={onClose}
  >
                              Close
                          </button>
                      </div>
                  </div>
              );
          },
          closeOnClickOutside: true,
      });
  };
    useEffect(() => {
     const fetchMasterData = async (keys) => {
        try {
          const config = {
            headers: {
              "rdxtoken": localStorage.getItem("tokenId"),
              "keys": JSON.stringify(keys),
            }
          };
          const response = await axios.get(apiBaseUrl + "master/globalmasterdetails", config);
          setGender(response.data[0].data || []);
          const updatedFormDetails = { ...formDetails };
          updatedFormDetails.gender.elementConfig.options = response.data[0].data || [];
          setFormDetails(updatedFormDetails);
        } catch (error) {
          console.log("Error fetching master data:", error);
        }
      };
      fetchMasterData(["gender"]);
    }, []); // Empty dependency array means this effect runs once on mount

    const checkValidity = (value, rules) => {
      let isValid = true;
      let errorMessage = '';
    
      if (rules.required) {
        isValid = value.trim() !== '' && value.trim() !== '0' && isValid;
        if (!isValid) errorMessage = 'This field is required.';
      }
    
      if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
        if (!isValid) errorMessage = `Minimum length is ${rules.minLength} characters.`;
      }
    
      if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
        if (!isValid) errorMessage = `Maximum length is ${rules.maxLength} characters.`;
      }
      if (rules.isAlpha) {
        const pattern = /^[A-Za-z]+$/;
        isValid = pattern.test(value) && isValid;
        if (!isValid) errorMessage = 'Please enter only alphabets.';
      }
      if (rules.isNumeric) {
        const pattern = /^\d+(\.\d+)?$/; // Allows only numbers and optional decimal points
        isValid = pattern.test(value) && isValid;
        if (!isValid) errorMessage = 'Please enter a valid number.';
      }
      if (rules.isFloat) {
        const pattern = /^[0-9]+(\.[0-9]+)?$/;
        isValid = pattern.test(value) && isValid;
        if (!isValid) errorMessage = 'Please enter a valid floating-point number.';
      }
    
      if (rules.validDate) {
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        isValid = datePattern.test(value) && new Date(value) <= new Date() && isValid;
        if (!isValid) errorMessage = 'Please enter a valid date (YYYY-MM-DD) and it cannot be in the future.';
      }
    
      if (rules.isEmail) {
        const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        isValid = pattern.test(value) && isValid;
        if (!isValid) errorMessage = 'Please enter a valid email address.';
      }
    
      // if (rules.isPhoneNumber) {
      //   const pattern = /^[0-9]{10}$/; // Example for 10-digit mobile number
      //   isValid = pattern.test(value) && isValid;
      //   if (!isValid) errorMessage = 'Please enter a valid 10-digit mobile number.';
      // }
      return { isValid, errorMessage };
    };
    
    
    
    const inputChangedHandler = (event, inputIdentifier) => {
      const updatedFormDetails = { ...formDetails };
      const updatedFormElement = { ...updatedFormDetails[inputIdentifier] };
    
      updatedFormElement.value = event.target.value;
    
      // Apply numeric validation for height and weight fields
      if (inputIdentifier === 'height' || inputIdentifier === 'weight') {
        updatedFormElement.validation.isNumeric = true;
      }
      if (inputIdentifier === 'mobile') {
        updatedFormElement.validation.isPhoneNumber = true;
      }
      const validation = checkValidity(updatedFormElement.value, updatedFormElement.validation);
    
      updatedFormElement.valid = validation.isValid;
      updatedFormElement.errorMessage = validation.errorMessage;
      updatedFormElement.touched = true;
    
      updatedFormDetails[inputIdentifier] = updatedFormElement;
      setFormDetails(updatedFormDetails);
    };
  const submitHandler = async (event) => {
      event.preventDefault();
      const formIsValid = Object.keys(formDetails).every((key) => formDetails[key].valid);

      if (!formIsValid) {
        showAlert('Please fill out all required fields correctly.', 'error');
        return;
      }

      try {
        const config = {
          headers: {
            "rdxtoken": localStorage.getItem("tokenId"), // Setting the token in the headers
          }
        };
        const response = await axios.post(apiBaseUrl + "patient/insertpatient", formDetails, config);
        showAlert('Patient added successfully', 'success');
        navigate('/patients/' + response.data.patient.patientguid);
      } catch (error) {
        showAlert(error, 'error');
        console.log("tokenerror", error);
      }
    };

    const formElementsArray = [];
    for (let key in formDetails) {
      formElementsArray.push({
        id: key,
        config: formDetails[key],
      });
    }

    return (
      <div className="form-container">
        <form className="form-content" onSubmit={submitHandler}>
          <div className="form-group">
          {formElementsArray.map((formElement, index) => (
    <div key={formElement.id}>
      <Input
        className="input-element"
        label={formElement.config.label}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        value={formElement.config.value}
        invalid={!formElement.config.valid && formElement.config.touched}
        errorMessage={formElement.config.errorMessage}
        changed={(event) => inputChangedHandler(event, formElement.id)}
      />
      {!formElement.config.valid && formElement.config.touched && (
        <p className="error-message">{formElement.config.errorMessage}</p>
      )}
    </div>
  ))}
          </div>
          <Button className="button-element" type="submit" label="Submit" />
        </form>
      </div>
    );
  };

  export default PersonalInfo;

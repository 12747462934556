// src/components/Charts/LineChart.js

import React from 'react';
import './Chart.css'; // Ensure you have a CSS file for styling

const NumberChart = ({ data }) => {
    // Handle empty data array or undefined data
    const count = data && data.length > 0 ? data[0].count : 'No data';

    return (
        <div className="number-chart-container">
            <span className="number-chart-value">{count}</span>
            <p className="number-chart-label">Count</p>
        </div>
    );
};

export default NumberChart;

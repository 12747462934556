import React, { useEffect, useState } from 'react';
import { FaFile, FaEye, FaTrashAlt, FaFileMedical } from 'react-icons/fa';

function MedicalRecord({ patientData, patientguid, onDataUpdate }) {
  const [files, setFiles] = useState([]);

  // Load existing files when the component mounts
  useEffect(() => {
    if (patientData.filedetails) {
      const existingFiles = patientData?.filedetails?.map(file => ({
        name: file.filename || 'Existing File',
        url: file.fileurl,
      }));
      setFiles(existingFiles);
    }   
  }, [patientData]);

  // Handle file upload
  const handleFileUpload = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    const updatedFiles = uploadedFiles.map((file) => ({
      file,
      name: file.name,
      url: URL.createObjectURL(file),
    }));

    setFiles((prevFiles) => {
      const allFiles = [...prevFiles, ...updatedFiles];
      onDataUpdate({ medicalFilesData: allFiles });
      return allFiles;
    });
  };

  // Handle file deletion with confirmation
  const handleDelete = (fileName) => {
    const confirmed = window.confirm(`Are you sure you want to delete ${fileName}?`);
    if (confirmed) {
      setFiles((prevFiles) => {
        const updatedFiles = prevFiles.filter((file) => file.name !== fileName);
        onDataUpdate({ medicalFilesData: updatedFiles });
        return updatedFiles;
      });
    }
  };

  return (
    <>
      <div className="flex flex-col gap-6">
        <div className="space-y-2">
          <h3 className="text-2xl font-semibold text-gray-800">Medical Files</h3>
          <h4 className="text-lg text-gray-600">Upload EHR data</h4>
          <p className="text-sm text-gray-500 italic">
            These files will be used to extract symptoms, genes, and other important information to facilitate analysis. 
            You can add or remove files at any time. If you don't have any files to upload, press next.
          </p>
        </div>

        <input
          type="file"
          multiple
          onChange={handleFileUpload}
          className="hidden"
          id="file-upload"
        />
        {files.length===0?
        <label htmlFor="file-upload" className="cursor-pointer">
          <div className="flex flex-col items-center justify-center p-4 border-2 border-dashed border-gray-300 rounded-lg bg-gray-50 hover:bg-gray-100 transition">
            <p className="text-gray-500 text-sm">Click or drop files here to upload</p>
          </div>
        </label>:null
        }

        <div>
          {files.length > 0 && (
            <div className="grid grid-cols-6 gap-4">
              {files.map((file, index) => (
                <div key={index} className="relative flex flex-col items-center p-2  w- h-30 border rounded-lg shadow-sm bg-white">
                  <div style={{paddingTop:25, paddingBottom:5}}>
                    <FaFileMedical size={24} className="text-gray-600" />
                  </div>
                  <h6 style={{padding:0, fontStyle:'italic', fontSize:12, margin:0}}>
                  {file.name.split('_')[0].length > 10 ? file.name.split('_')[0].slice(0, 2) + (file.name.split('_')[0].length > 2 ? '...' : ''): file.name.split('_')[0]}
                  </h6>
                  <div className="absolute top-1 right-1 flex space-x-2">
                    <a href={file.url} target="_blank" rel="noopener noreferrer" className="text-blue-900 hover:text-blue-700 transition">
                      <FaEye size={16} />
                    </a>
                    <button
                      onClick={() => handleDelete(file.name)}
                      className="text-red-500 hover:text-red-700 transition"
                    >
                      <FaTrashAlt size={12} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default MedicalRecord;

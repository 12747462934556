import AWS from 'aws-sdk';

export function getFileExtension(filePath) {
    //return filePath.substr(filePath.lastIndexOf("\\") + 1).split(".")[1];
    var filename = filePath.substr(filePath.lastIndexOf("\\") + 1)
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(filename)[1];
    return ext;
    // changes for filename with multiple dots.
}

export function getS3Details()
{
    const data =new AWS.S3({
        accessKeyId: 'AKIAUCFV3ISYTDRAVJUM',//process.env.AWS_ACCESS_KEY_ID, // Ensure these environment variables are set
        secretAccessKey: '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',//process.env.AWS_SECRET_ACCESS_KEY,
        region:'us-east-1' //process.env.AWS_REGION,
    });
    return data;
      
}
import React, { useState, useEffect } from 'react';
import { Checkbox } from '../../components/Form';
import axios from 'axios';
import { getServiceUrl } from '../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt, FaInfoCircle, FaSearch } from 'react-icons/fa';
import './Patient.css'; // Import custom CSS file
import Loader from '../../components/CustomUI/Loader'
import NodataBox from '../../components/CustomUI/NoDataBox';
import { RiAlignItemBottomFill } from 'react-icons/ri';
import DatePopup from '../../components/CustomUI/DatePopup';
import SymptomDetailsPopup from '../../components/CustomUI/SymptomDetailsPopup';
const apiBaseUrl = getServiceUrl();

const Symptoms = ({ patientData, patientguid, onDataUpdate }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [coOccurSymptoms, setCoOccurSymptoms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showCoOccuring, setShowCoOccuring] = useState(false); // State for popup visibility
  const [activeDatePopup, setActiveDatePopup] = useState(null); // State for popup visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSymptom, setSelectedSymptom] = useState(null);
 
  const CoOccurringSymptomsPopup = ({ onClose }) => {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="cooccur-popup relative bg-white rounded-lg shadow-lg max-h-[80vh] w-full max-w-lg overflow-hidden flex flex-col">
  
          {/* Fixed Header */}
          <div className="cooccur-popup-header bg-white p-4 flex justify-between items-center">
            <h4 className="text-lg font-semibold">Co-Occurring Symptoms</h4>
            <button
              onClick={onClose}
              className="text-gray-600 hover:text-gray-800 focus:outline-none text-lg"
            >
              &times; {/* Close Icon */}
            </button>
          </div>
  
          {/* Scrollable Content Area */}
          <div className="cooccur-popup-content overflow-y-auto flex-grow p-4">
            {coOccurSymptoms && coOccurSymptoms.length > 0 ? (
              suggestions
                .filter((item) => coOccurSymptoms.includes(item.hpoid))
                .map((item) => (
                  <div key={item.hpoid} className="selected-item mb-3">
                    <div className="selected-item-header">
                      <Checkbox
                        label={
                          <span className="item-label font-medium">
                            {item.name}
                          </span>
                        }
                        checked={selectedItems.some((x) => x.hpoid === item.hpoid)}
                        onChange={() => handleSuggestionClick(item)}
                      />
                    </div>
                    {item.synonyms && (
                      <div className="item-synonyms text-sm text-gray-500 mt-1">
                        Synonyms: {item.synonyms.split(' ').slice(0, 15).join(' ')}...
                      </div>
                    )}
                  </div>
                ))
            ) : (
              <NodataBox
                mainText="No Co-Occurring Symptoms Found"
                subText="Please try again later or check your data source."
              />
            )}
          </div>
  
          {/* Fixed Footer */}
          <div className="cooccur-popup-footer bg-white p-4 flex justify-end">
            <button
              onClick={onClose}
              className="bg-blue-900 text-white py-2 px-4 rounded mr-2"
            >
              Save Changes
            </button>
            </div>
        </div>
      </div>
    );
  };
  
  
  
  
  
  
const getCoOccuringSymptoms=async()=>{
    setLoading(true)
    const formBody = {
      symptoms: selectedItems.filter(x => x.hpoid).map(x => x.hpoid),
      patient_id:patientguid
    };
    const config = {
      headers: {
        'rdxtoken': localStorage.getItem('tokenId'),
      },
    };
    const response = await axios.post(apiBaseUrl + 'doctors/cooccur', formBody, config);
    setCoOccurSymptoms(response.data.python_api_response.suggested_hpoids)
    setLoading(false)
  }
  const getPatientSymptoms=async()=>{

    // Fetch patient's symptoms
    const patientConfig = {
      headers: {
        rdxtoken: localStorage.getItem('tokenId'),
        patientguid: patientguid,
      },
    };
    const patientResponse = await axios.get(apiBaseUrl + 'doctors/getpatientsymptoms', patientConfig);
  return patientResponse.data[0]?.symptomdetails || [];

  }
  const getSymptoms=async()=>{
    const config = {
      headers: {
        rdxtoken: localStorage.getItem('tokenId'),
      },
    };

    // Fetch master symptoms
    const masterResponse = await axios.get(apiBaseUrl + 'doctors/symptoms', config);
   return masterResponse.data || [];
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
       
       const masterSymptoms=await getSymptoms()
        setSuggestions(masterSymptoms);
  
         const existingSymptoms =await getPatientSymptoms();  
        // Map through existing symptoms and find matching details
        const preSelectedItems = existingSymptoms
          .map(symptom =>
            {
              const suggestion = masterSymptoms.find(s => s.hpoid === symptom.hpoid);
              return {
                hpoid: symptom.hpoid,
                synonyms: suggestion?.synonyms || '',
                name: suggestion?.name || '',
                comment: suggestion?.comment || '',
                startDate: symptom.startdate || null,
                endDate: symptom.enddate || null,
                source:symptom.source,
                checked: true,
              };
            }
          )
          .flat(); // Flatten the array after mapping
        setSelectedItems(preSelectedItems);
        handleDataUpdateAction(preSelectedItems);
        setLoading(false)
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };
  
    fetchData();
    
  }, [patientguid]);
  

  const handleDataUpdateAction = (data) => {
    onDataUpdate({ symptomsData: data });
  };

  const handleChange = (e) => {
    const userInput = e.target.value;
    setQuery(userInput);
  
    if (userInput.length > 0) {
      const filtered = suggestions
        .filter(
          (suggestion) =>
            (suggestion.name.toLowerCase().includes(userInput.toLowerCase()) ||
            (suggestion.synonyms && suggestion.synonyms.toLowerCase().includes(userInput.toLowerCase()))) &&
            !selectedItems.find((item) => item.name === suggestion.name)
        )
        .slice(0, 10);
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
  };
  

  const handleSuggestionClick = (suggestion) => {
    const isItemSelected = selectedItems.find((item) => item.hpoid === suggestion.hpoid);
  
    // If the item is already selected, remove it
    if (isItemSelected) {
      setSelectedItems((prevItems) => {
        const updatedItems = prevItems.filter((item) => item.hpoid !== suggestion.hpoid);
        handleDataUpdateAction(updatedItems);
        return updatedItems;
      });
    } 
    // If the item is not selected, add it
    else {
      setSelectedItems((prevItems) => {
        const updatedItems = [
          ...prevItems,
          {
            hpoid: suggestion.hpoid,
            synonyms: suggestion.synonyms,
            name: suggestion.name,
            comment: suggestion.comment,
            startDate: null,
            endDate: null,
            checked: true,
          },
        ];
  
        handleDataUpdateAction(updatedItems);
  
        return updatedItems;
      });
    }
  
    setQuery('');
    setFilteredSuggestions([]);
    setShowSuggestions(false);
  };
  
  function formatDate(dateString) {
    if(!dateString)
      return null
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' }); // Get the full month name
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }
  const handleTagToggle = (item) => {
    setSelectedItems((prevItems) => {
      const updatedItems = prevItems.map((i) =>
        i.hpoid === item.hpoid ? { ...i, checked: !i.checked } : i
      );
      const checkedItems = updatedItems.filter((i) => i.checked);
      handleDataUpdateAction(checkedItems);
      return updatedItems;
    });
    
  };  
  

  const handleDateChange = (startDate, endDate) => {
    if (activeDatePopup) {
      const updatedItems = selectedItems.map((item) =>
        item.hpoid === activeDatePopup.hpoid
          ? { ...item, startDate, endDate }
          : item
      );
      setSelectedItems(updatedItems);
      handleDataUpdateAction(updatedItems);
    }
  };

  const handleCalendarClick = (item) => {
    setActiveDatePopup(item);
  };
  const handleInfoClick = (item) => {
    setSelectedSymptom(item);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSymptom(null);
  };

  const handleCloseDatePopup = () => {
    setActiveDatePopup(null);
  };
  return (
    loading?<Loader/>:
    <div style={{ margin: 'auto' }}>
      <div style={{display: 'flex', justifyContent: 'flex-end', paddingBottom: '10px' }}>
        {selectedItems.filter((i) => i.checked).length>3?
      <button 
      onClick={() => 
        {
          getCoOccuringSymptoms()
          setShowCoOccuring(true)
        }
        } 
        className="bg-yellow-500 text-white py-1 px-2 rounded-md text-sm mb-2 ml-right">
      Co-Occurring Symptoms
    </button>
    : <button 
    style={{cursor:'not-allowed'}}
    className="bg-yellow-200 text-white py-1 px-2 rounded-md text-sm">
    Co-Occurring Symptoms
  </button>}
        </div>
      <div className="flex w-full flex-col gap-5">
        <div style={{ position: 'relative' }}>
     {(patientData.status === 'new' && patientData.filedetails[0] !== undefined) ? (
                <div className="flex items-center p-3 bg-blue-50" style={{paddingBottom:10, marginBottom:5}}>
                   <FaInfoCircle className="text-blue-900 mr-2"/>
                    <span className="text-blue-900" style={{ fontSize: '12px'}}>
                    We are retrieving symptoms from the report. You may add additional symptoms from the list below.
                    </span>
                </div>
            ) : null}   
        <div className="relative flex-1">
        
          <input
            type="text"
            value={query}
            onChange={handleChange}
            placeholder="Type to search..."
            className="border rounded pl-10 pr-4 py-2 w-full"
          />
           <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
        </div>

          {showSuggestions && (
            <ul className="suggestions-list">
              {filteredSuggestions.map((suggestion) => (
                <li
                  key={suggestion.hpoid}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="suggestion-item"
                >
                  <span className="suggestion-name">{suggestion.name}</span>
                  <span className="suggestion-synonyms">{suggestion.synonyms}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {selectedItems.map((item) => (
        <div
          key={item.hpoid}
          className="selected-item"
        >
          <div className="selected-item-header">
            <Checkbox
              label={
                <span className="item-label">
                  {item.name}
                  <FaInfoCircle
                  className="info-icon text-blue-500 cursor-pointer"
                  onClick={() => handleInfoClick(item)}
                />
                </span>
              }
              checked={item.checked}
              onChange={() => handleTagToggle(item)}
            />
            
             {item.checked && (
               <div className="date-picker-card">
               <div className="date-picker-body">
                 <div className="date-text-container">
                   <p className="date-text">{formatDate(item.startDate)}</p>
                   <p className="date-text">{formatDate(item.endDate)}</p>
                   <FaCalendarAlt
                   className="calendar-icon"
                   onClick={() => handleCalendarClick(item)}
                 />
                 </div>
              
                   <h4 className="source-text-source">{item.source}</h4>
               </div>
             </div>
             )}
          </div>
          {item.synonyms && (
            <div className="item-synonyms">
              Synonyms: {item.synonyms}
            </div>
          )}
        </div>
      ))}
    {showCoOccuring && (
        <div style={{ maxWidth: '40rem' , maxHeight: '40rem' }} className="absolute inset-0 flex items-center justify-center">
          <CoOccurringSymptomsPopup onClose={() => setShowCoOccuring(false)} />
        </div>
      )}
      {activeDatePopup && (
          <DatePopup
            item={activeDatePopup}
            onClose={handleCloseDatePopup}
            onDateChange={handleDateChange}
          />
        )}
        {/* Modal for displaying symptom details */}
      {selectedSymptom && (
        <SymptomDetailsPopup 
        isOpen={isModalOpen}
         onClose={closeModal} 
         selectedSymptom={selectedSymptom}
         contentLabel="Symptom Details"/>
       )}
    </div>
  );
}

export default Symptoms;

import React from 'react';
import './Input.css';

const Input = (props) => {
  let inputElement = null;
  const inputClasses = ['input-element'];
  let validationError = null;

  if (props.invalid && props.shouldValidate && props.touched) {
    inputClasses.push('invalid');
    validationError = <p className="validation-error">{props.errorMessage}</p>;
  }

  switch (props.elementType) {
    case 'input':
      inputElement = (
        <input
          className={inputClasses.join(' ')}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
    case 'textarea':
      inputElement = (
        <textarea
          className={inputClasses.join(' ')}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
    case 'select':
      inputElement = (
        <select
          className={inputClasses.join(' ')}
          value={props.value}
          onChange={props.changed}
        >
          <option value="0" key="0">
            -- Select --
          </option>
          {props.elementConfig.options.map((option) => (
            <option value={option.Id} key={option.Id}>
              {option.Value}
            </option>
          ))}
        </select>
      );
      break;
    default:
      inputElement = (
        <input
          className={inputClasses.join(' ')}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
  }

  return (
    <div className="input-group">
      <label className="input-label">{props.label}</label>
      {inputElement}
      {validationError}
    </div>
  );
};

export default Input;

import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';

// Utility function to truncate text after 20 words
const truncateText = (text, wordLimit) => {
  if (!text) return 'N/A';
  const words = text.split(' ');
  return words.length > wordLimit
    ? words.slice(0, wordLimit).join(' ') + '...'
    : text;
};

const SymptomDetailsPopup = ({ selectedSymptom, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm z-50">
      <div className="bg-white rounded-3xl shadow-xl w-full max-w-2xl h-auto p-8 relative overflow-y-auto transition-all duration-300 ease-in-out">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none text-3xl"
        >
          &times;
        </button>

        {/* Symptom Details */}
        <div className="space-y-6">
          {/* Title */}
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Symptom Details</h2>

          {/* Symptom Info */}
          <div className="grid grid-cols-2 gap-6">
            {/* Symptom ID */}
            <div className="flex flex-col">
              <span className="text-gray-500 font-medium">Symptom ID</span>
              <span className="text-gray-800 text-lg font-semibold break-words">{selectedSymptom.hpoid || 'N/A'}</span>
            </div>

            {/* Symptom Name */}
            <div className="flex flex-col">
              <span className="text-gray-500 font-medium">Name</span>
              <span className="text-gray-800 text-lg font-semibold break-words">{selectedSymptom.name || 'N/A'}</span>
            </div>

            {/* Symptom Synonyms */}
            <div className="flex flex-col col-span-2">
              <span className="text-gray-500 font-medium">Synonyms</span>
              <span className="text-gray-800 text-lg font-normal break-words">
                {truncateText(selectedSymptom.synonyms, 20)}
              </span>
            </div>

            {/* Symptom Comment */}
            <div className="flex flex-col col-span-2">
              <span className="text-gray-500 font-medium">Comment</span>
              <span className="text-gray-800 text-lg font-normal break-words">
                {truncateText(selectedSymptom.comment, 20)}
              </span>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-center mt-8">
          <button
            onClick={onClose}
            className="bg-blue-500 text-white py-2 px-8 rounded-full hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SymptomDetailsPopup;

import React, { useState, useEffect } from 'react';
import Layout from '../../Layout';
import { Link, useParams } from 'react-router-dom';
import { BiPlus } from 'react-icons/bi';
import PatientBasicDetails from './PatientBasicDetails';

const thclass = 'text-start text-sm font-medium py-3 px-2 whitespace-nowrap';
const tdclass = 'text-start text-sm py-4 px-2 whitespace-nowrap';

function getDiseasePredictions(patientguid) {
  // Simulate fetching data
  let diseaseList = [];
  return diseaseList;
}

export default function Dashboard() {
  
  const patientData  = localStorage.getItem("patientData")!==null?JSON.parse(localStorage.getItem("patientData")):[]

  const { patientguid } = useParams();
  const [diseaseList, setDiseaseList] = useState([]);

  useEffect(() => {
    const fetchDiseasePredictions = async () => {
      const predictions = getDiseasePredictions(patientguid);
      setDiseaseList(predictions);
    };

    fetchDiseasePredictions();
  }, [patientguid]);

  return (
    <Layout patientguid={patientguid}>
        {
       patientData?<PatientBasicDetails patientData ={ patientData }/>:null
    }
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="10"
        data-aos-offset="200"
        className="bg-white my-8 rounded-xl border-[1px] border-border p-5"
      >
        Details
        </div>
    </Layout>
  );
}

import React, { useState, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { AiOutlineClose } from 'react-icons/ai';
import { FaSearch } from 'react-icons/fa';
import NodataBox from '../../components/CustomUI/NoDataBox';

const TestList = ({ isOpen, onClose, testList }) => {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedTest, setSelectedTest] = useState(null);
  const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);

  const itemsPerPage = 5;

  // Filter and pagination logic
  const filteredTests = useMemo(() => {
    return testList.filter((test) =>
      Object.values(test).some((value) =>
        value.toString().toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [testList, search]);

  const offset = currentPage * itemsPerPage;
  const currentItems = useMemo(() => filteredTests.slice(offset, offset + itemsPerPage), [filteredTests, offset, itemsPerPage]);
  const pageCount = Math.ceil(filteredTests.length / itemsPerPage);

  const handlePageClick = (data) => setCurrentPage(data.selected);

  const handleBookTest = (test) => {
    setSelectedTest(test);
    setConfirmPopupOpen(true);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-60">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-4xl p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <AiOutlineClose size={20} />
        </button>
        {
            testList.length>0?
            <React.Fragment>
                {/* Search Bar */}
        <div className="mb-4 flex items-center relative">
          <input
            type="text"
            placeholder="Search tests..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="border border-gray-300 rounded pl-10 pr-4 py-2 w-1/2 text-sm"
          />
          <FaSearch className="absolute left-3 text-gray-500" />
        </div>

        {/* Tests Table */}
        <div className="overflow-auto max-h-96">
          <table className="min-w-full text-sm border-collapse">
            <thead>
              <tr className="text-left bg-blue-900">
                <th className="p-2 text-white border-b">Disease</th>
                <th className="p-2 text-white border-b">Broad Category</th>
                <th className="p-2 text-white border-b">Method</th>   
                <th className="p-2 text-white border-b">Lab</th>               
                <th className="p-2 text-white border-b text-right">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((test) => (
                <tr key={test.test_accession} className="hover:bg-gray-50">
                  <td className="p-2 whitespace-nowrap max-w-xs overflow-hidden overflow-ellipsis">
                    {test.condition_identifiers}
                  </td>
                  <td className="p-2 whitespace-nowrap max-w-xs overflow-hidden overflow-ellipsis">
                    {test.method_categories}
                  </td>
                  <td className="p-2 whitespace-nowrap max-w-xs overflow-hidden overflow-ellipsis">
                    {test.methods}
                  </td>
                  <td className="p-2 whitespace-nowrap max-w-xs overflow-hidden overflow-ellipsis">
                    {test.name_of_laboratory}
                  </td>
                  <td className="p-2 text-right">
                    <button
                      onClick={() => handleBookTest(test)}
                      className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 text-xs"
                    >
                      Book
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        {pageCount > 1 && (
          <div className="mt-4 flex justify-start">
            <ReactPaginate
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName="flex space-x-2"
              pageClassName="px-3 py-1 bg-gray-200 border rounded"
              activeClassName="bg-blue-500 text-white"
              previousLabel="Previous"
              nextLabel="Next"
              breakLabel="..."
            />
          </div>
        )}
            </React.Fragment>:
             <NodataBox mainText="You test details found" subText="We will update the test details soon"/>
        }
        
      </div>

      {/* Confirmation Popup */}
      {confirmPopupOpen && (
        <ConfirmBookingPopup
          test={selectedTest}
          onClose={() => setConfirmPopupOpen(false)}
        />
      )}
    </div>
  );
};

// Confirmation Popup Component
const ConfirmBookingPopup = ({ test, onClose }) => {
  const handleConfirmBooking = () => {
    alert(`Test Booking Confirmed for: ${test.test_accession}`);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-60">
      <div className="bg-white rounded-lg shadow-lg p-6 relative w-80">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <AiOutlineClose size={20} />
        </button>
        <h2 className="text-lg font-semibold mb-4">Confirm Booking</h2>
        <p className="text-sm mb-6">
          Are you sure you want to book the test with Accession{' '}
          <strong>{test.test_accession}</strong>?
        </p>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 text-sm"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirmBooking}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestList;

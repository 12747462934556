import React, { useState, useEffect } from 'react';
import Layout from '../../Layout';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { getServiceUrl } from '../../config';
import { AiOutlineArrowUp, AiOutlineArrowDown, AiOutlineClose } from 'react-icons/ai';
import PatientBasicDetails from './PatientBasicDetails';
import ReactPaginate from 'react-paginate';
import Loader from '../../components/CustomUI/Loader';
import NodataBox from '../../components/CustomUI/NoDataBox';
import { FaSearch, FaBook } from 'react-icons/fa';
import TestList from './TestList';
const thclass = 'text-start text-sm font-medium py-3 px-4 cursor-pointer whitespace-nowrap flex items-center';
const tdclass = 'text-start text-sm py-4 px-4 whitespace-nowrap overflow-hidden text-ellipsis';

const apiBaseUrl = getServiceUrl();

export default function Diseases() {
  const patientData = localStorage.getItem("patientData") !== null ? JSON.parse(localStorage.getItem("patientData")) : [];

  const navigate = useNavigate();
  const { patientguid } = useParams();
  const [diseaseList, setDiseaseList] = useState([]);
  const [testList, setTestList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0); // Page index starts at 0
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDisease, setSelectedDisease] = useState(null);
  const [isDetailsPopupOpen, setIsDetailsPopupOpen] = useState(false);
  
  const getTestDetails = async (diseaseName) => {
    const formBody = { condition_name: diseaseName };
    const config = {
      headers: { rdxtoken: localStorage.getItem('tokenId') },
    };

    try {
      const response = await axios.post(apiBaseUrl + 'doctors/tests', formBody, config);
      debugger
      setTestList(response.data);
    } catch (error) {
      console.error("Error fetching test details:", error);
    }
  };

  useEffect(() => {
    const fetchDiseasePredictions = async () => {
      try {
        const config = {
          headers: {
            rdxtoken: localStorage.getItem('tokenId'),
            patientguid: patientguid
          },
        };
        const response = await axios.get(apiBaseUrl + 'doctors/predict', config);
        setDiseaseList(response.data.predictions);
        setFilteredData(response.data.predictions);
      } catch (error) {
        console.error("Error fetching disease predictions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDiseasePredictions();
  }, [patientguid]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setIsDetailsPopupOpen(false);
      }
    };

    if (isDetailsPopupOpen) {
      window.addEventListener('keydown', handleEsc);
    }

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isDetailsPopupOpen]);

  const handleClosePopup = () => {
    setIsDetailsPopupOpen(false); // Close the popup
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setSortConfig({ key, direction });
    setFilteredData(sortedData);
  };

  const handleRowClick = (disease) => {
    navigate(`/patients/diseasedetails/${patientguid}/${disease.Disease}`);
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = diseaseList.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(query)
      )
    );

    setFilteredData(filtered);
    setCurrentPage(0); // Reset to first page on search
  };

  const handleOpenDetailsPopup = (disease) => {
    setSelectedDisease(disease);
    getTestDetails(disease.Disease);
    setIsDetailsPopupOpen(true);
  };

  const handleCloseDetailsPopup = () => {
    setIsDetailsPopupOpen(false);
  };

  // Pagination logic
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (data) => {
    setCurrentPage(data.selected);
  };

  return (
    <Layout patientguid={patientguid}>
      {patientData ? <PatientBasicDetails patientData={patientData} /> : null}
      <React.Fragment>
        {loading ? <Loader /> :
          <React.Fragment>
            <div className="bg-white my-8 rounded-xl border border-gray-300 shadow-md p-6">
              <div className="flex justify-between items-center mb-4">
                <div className="text-sm text-gray-700">
                  Total Records: {filteredData.length}
                </div>
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearch}
                  className="p-2 border border-gray-300 rounded-lg w-48 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-900 transition duration-200"
                />
              </div>
              {currentItems.length > 0 ? (
                <>
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full bg-gray-100 border border-gray-300 rounded-lg">
                      <thead className="bg-blue-900 text-white rounded-t-lg">
                        <tr>
                          <th onClick={() => handleSort('Disease')} className={`p-3 text-left border-b border-gray-300 cursor-pointer ${sortConfig?.key === 'Disease' ? 'bg-gray-300' : ''}`}>
                            Disease {sortConfig && sortConfig.key === 'Disease' ? (sortConfig.direction === 'asc' ? <AiOutlineArrowUp className="inline-block ml-1" /> : <AiOutlineArrowDown className="inline-block ml-1" />) : null}
                          </th>
                          <th onClick={() => handleSort('RDX_Rank')} className={`p-3 text-left border-b border-gray-300 cursor-pointer ${sortConfig?.key === 'RDX_Rank' ? 'bg-gray-300' : ''}`}>
                            RDX Rank {sortConfig && sortConfig.key === 'RDX_Rank' ? (sortConfig.direction === 'asc' ? <AiOutlineArrowUp className="inline-block ml-1" /> : <AiOutlineArrowDown className="inline-block ml-1" />) : null}
                          </th>
                          <th onClick={() => handleSort('Match_Percentage')} className={`p-3 text-left border-b border-gray-300 cursor-pointer ${sortConfig?.key === 'Match_Percentage' ? 'bg-gray-300' : ''}`}>
                            RDX Percentage {sortConfig && sortConfig.key === 'Match_Percentage' ? (sortConfig.direction === 'asc' ? <AiOutlineArrowUp className="inline-block ml-1" /> : <AiOutlineArrowDown className="inline-block ml-1" />) : null}
                          </th>
                          <th className="p-3 text-left border-b border-gray-300">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((item, index) => (
                          <tr key={index} className="hover:bg-gray-100 cursor-pointer">
                            <td className={tdclass} onClick={() => handleRowClick(item)}>{item.Disease}</td>
                            <td className={tdclass}>{item.RDX_Rank}</td>
                            <td className={tdclass}>{item.Match_Percentage}</td>
                            <td className={tdclass}>
                              <button
                                className="bg-blue-500 text-white px-4 py-1 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                onClick={(e) => { e.stopPropagation(); handleOpenDetailsPopup(item); }}
                              >
                                Details
                              </button>
                             </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={Math.ceil(filteredData.length / itemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </>
              ) : (
                <NodataBox mainText="You test details found" subText="We will update the test details soon"/>
              )}
            </div>
            {isDetailsPopupOpen && (
           <TestList
                isOpen={isDetailsPopupOpen}
                onClose={handleCloseDetailsPopup}
                testList={testList}
              />
           )}
            </React.Fragment>
        }
      </React.Fragment>
    </Layout>
  );
}

// src/components/Charts/DonutChart.js

import React from 'react';
import Chart from 'react-apexcharts';

const DonutChart = ({ data }) => {
  const chartOptions = {
    chart: {
      type: 'donut',
      height: 400, // Increased height for a larger chart
      toolbar: { show: false }, // Hide toolbar for a cleaner look
    },
    labels: data.map(item => item.value),
    colors: ['#F69600', '#3BB27B', '#F4A460', '#8B0000', '#123569'],
    legend: {
      show: false,
      position: 'bottom',
      labels: {
        colors: '#333', // Subtle legend color
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '16px', // Adjusted font size for better readability
              color: '#333',
            },
            value: {
              show: true,
              fontSize: '24px', // Increased font size for better visibility
              color: '#333',
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false, // Hide data labels for minimalism
    },
    tooltip: {
      enabled: true, // Keep tooltips for interaction
    },
  };

  const chartSeries = data.map(item => item.count);

  return (
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="donut"
          width="100%" // Ensure the chart takes full width of the container
        />
  );
};

export default DonutChart;

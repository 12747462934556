import React from 'react';
import { useNavigate } from 'react-router-dom';

function PatientBasicDetails({patientData}) {
  const navigate = useNavigate();
  const {
    firstname,
    age,
    gender,
    patientguid,
    createddate,
    symptomdetails
  } = patientData

  return (
    
    <div className="bg-white border rounded-xl shadow-sm p-4 mb-6">
    <table className="w-full">
      <thead>
        <tr className="border-b border-gray-200">
          <th className="py-2 px-3 text-left text-gray-700">Name</th>
          <th className="py-2 px-3 text-left text-gray-700">Age</th>
          <th className="py-2 px-3 text-left text-gray-700">Symptoms</th>
          <th className="py-2 px-3 text-left text-gray-700">Gender</th>
          <th className="py-2 px-3 text-left text-gray-700">Created On</th>
          <th className="py-2 px-3 text-left text-gray-700">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="py-2 px-3 border-b border-gray-100">{firstname}</td>
          <td className="py-2 px-3 border-b border-gray-100">{age}</td>
          <td className="py-2 px-3 border-b border-gray-100">{symptomdetails?.length||'NA'}</td>
          <td className="py-2 px-3 border-b border-gray-100">{gender}</td>
          <td className="py-2 px-3 border-b border-gray-100">{new Date(createddate).toLocaleDateString()}</td>
          <td className="py-2 px-3 border-b border-gray-100">
                  <button
                    onClick={() => navigate(`/patients/${patientguid}`)}
                    className="bg-blue-900 text-white px-3 py-1 rounded"
                  >
                    View Data
                  </button>
                </td>
        </tr>
      </tbody>
    </table>
  </div>
  );
}

export default PatientBasicDetails;

import React, { useState } from 'react';
import { HiX } from 'react-icons/hi';
import { Button } from '../components/Form';
import { confirmAlert } from 'react-confirm-alert';
import { getServiceUrl } from '../config';
import axios from 'axios';
import { FaGoogle, FaFacebook } from 'react-icons/fa';
import zxcvbn from 'zxcvbn';

const apiBaseUrl = getServiceUrl();

const showAlert = (message, type) => {
    const overlayStyle = {
        backdropFilter: 'blur(8px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1000,
    };

    const panelStyle = {
        backgroundColor: '#ffffff',
        color: type === 'error' ? '#e74c3c' : '#2ecc71',
        borderRadius: '10px',
        padding: '20px',
        width: '90%',
        maxWidth: '400px',
        textAlign: 'center',
        boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
        border: `1px solid ${type === 'error' ? '#e74c3c' : '#2ecc71'}`,
        animation: 'fadeIn 0.3s ease-in-out',
    };

    const buttonStyle = {
        backgroundColor: type === 'error' ? '#e74c3c' : '#2ecc71',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '8px 20px',
        cursor: 'pointer',
        fontSize: '14px',
        marginTop: '15px',
    };

    confirmAlert({
        customUI: ({ onClose }) => (
            <div style={overlayStyle}>
                <div style={panelStyle}>
                    {/* <h2>{type === 'error' ? '' : ''}</h2> */}
                    <p>{message}</p>
                    <button style={buttonStyle} onClick={onClose}>Close</button>
                </div>
            </div>
        ),
        closeOnClickOutside: true
    });
};

const Signup = ({ onClose }) => {
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        mobile: '',
        password: ''
    });
    const [passwordStrength, setPasswordStrength] = useState('');
    const [errors, setErrors] = useState({});

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validateForm = () => {
        const newErrors = {};

        // Firstname and Lastname validation
        const nameRegex = /^[A-Za-z]+$/;
        if (!nameRegex.test(formData.firstname)) {
            newErrors.firstname = 'Only letters are allowed';
        }
        if (!nameRegex.test(formData.lastname)) {
            newErrors.lastname = 'Only letters are allowed';
        }

        // Email validation
        if (!validateEmail(formData.email)) {
            newErrors.email = 'Invalid email format';
        }

        // Mobile validation
        const mobileRegex = /^[0-9]+$/;
        if (!mobileRegex.test(formData.mobile)) {
            newErrors.mobile = 'Should contain only numbers';
        }
        if (formData.mobile.length < 10 || formData.mobile.length > 15) {
            newErrors.mobile = 'Invalid mobile number';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        if (name === 'password') {
            const { score } = zxcvbn(value);
            const strength = ['Weak', 'Fair', 'Good', 'Strong'][score];
            setPasswordStrength(strength);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            //showAlert('Please fix the errors in the form.', 'error');
            return;
        }

        const formBody = {
            firstname: formData.firstname,
            lastname: formData.lastname,
            email: formData.email,
            mobile: formData.mobile,
            password: formData.password
        };

        const config = {
            headers: {
                rdxtoken: localStorage.getItem('tokenId'),
            },
        };

        try {
            const response = await axios.post(apiBaseUrl + 'doctors/newuser', formBody, config);
            showAlert(response.data.message);
            if (response.data.user) onClose();
        } catch (error) {
            showAlert('An error occurred. Please try again.', 'error');
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4 transition-all duration-300 ease-in-out">
            <div className="relative bg-white p-8 rounded-lg shadow-lg w-full max-w-md mx-auto animate-fade-in">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                    <HiX className="h-6 w-6" />
                </button>
                <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">Create Your Account</h2>

                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="firstname" className="block text-gray-700 text-sm font-medium mb-1">First Name</label>
                            <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                value={formData.firstname}
                                onChange={handleChange}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
                                placeholder="Sumit"
                            />
                            {errors.firstname && <p className="text-red-600 text-sm mt-1">{errors.firstname}</p>}
                        </div>
                        <div>
                            <label htmlFor="lastname" className="block text-gray-700 text-sm font-medium mb-1">Last Name</label>
                            <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                value={formData.lastname}
                                onChange={handleChange}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
                                placeholder="Reddy"
                            />
                            {errors.lastname && <p className="text-red-600 text-sm mt-1">{errors.lastname}</p>}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                            <label htmlFor="email" className="block text-gray-700 text-sm font-medium mb-1">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
                                placeholder="example@vgenomics.com"
                            />
                            {errors.email && <p className="text-red-600 text-sm mt-1">{errors.email}</p>}
                        </div>
                        <div>
                            <label htmlFor="mobile" className="block text-gray-700 text-sm font-medium mb-1">Mobile</label>
                            <input
                                type="tel"
                                id="mobile"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                                required
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
                                placeholder="+91 8979510189"
                            />
                            {errors.mobile && <p className="text-red-600 text-sm mt-1">{errors.mobile}</p>}
                        </div>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-gray-700 text-sm font-medium mb-1">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-900"
                            placeholder="******"
                        />
                        <p className="text-gray-600 text-sm mt-1">{passwordStrength && `Password Strength: ${passwordStrength}`}</p>
                    </div>

                    <Button label="Register" type="submit" customClass="w-full py-2 rounded-md bg-blue-900 text-white hover:bg-blue-700 transition-all duration-200" />
                </form>
            </div>
        </div>
    );
};

export default Signup;

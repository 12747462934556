import React from 'react';
import './Loader.css'; // Import your CSS

const Loader = () => {
    return (
        <div className="loader-overlay">
            <div className="loader">
                <div className="pulse"></div>
            </div>
            <p className="loading-text">Processing your request...</p>
        </div>
    );
};

export default Loader;

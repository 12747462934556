import React from 'react';
import { Link } from 'react-router-dom';
import { BiPlus, BiSad } from 'react-icons/bi';

const PatientsNoDataBox = ({ message, buttonLabel, buttonLink }) => {
  return (
    <div className="flex flex-col items-center justify-center py-20 bg-gray-200">
      {/* Icon */}
      <div className="flex items-center justify-center bg-gray-200 rounded-full p-6 mb-4">
        <BiSad className="text-gray-500 text-6xl" />
      </div>

      {/* Message */}
      <p className="text-xl font-medium text-gray-600 mb-6">{message}</p>

      {/* Add Button */}
      <Link
        to={buttonLink}
        className="flex items-center bg-subMain text-white px-6 py-3 rounded-lg shadow-md hover:bg-subMain-dark transition"
      >
        <BiPlus className="inline-block mr-2" />
        {buttonLabel}
      </Link>
    </div>
  );
};

export default PatientsNoDataBox;

// src/components/Charts/BarChart.js

import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = ({ data }) => {
  const chartOptions = {
    chart: {
      type: 'bar',
      height: 250,
    },
    xaxis: {
      categories: data?.map(item => item.value),
      labels: {
        rotate: -45,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#123569'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
  };

  const chartSeries = [
    {
      name: 'Count',
      data: data.map(item => item.count),
    },
  ];

  return (
    <Chart
      options={chartOptions}
      series={chartSeries}
      type="bar"
      width="100%"
    />
  );
};

export default BarChart;

import React, { useEffect, useState } from 'react';
import Layout from '../../Layout';
import { Link, useNavigate } from 'react-router-dom';
import { BiPlus } from 'react-icons/bi';
import { PatientTable } from '../../components/Tables';
import axios from 'axios';
import { getServiceUrl } from '../../config';
import Loader from '../../components/CustomUI/Loader';
import NodataBox from '../../components/CustomUI/NoDataBox';
import PatientsNoDataBox from '../../components/CustomUI/PatientsNoDataBox';

const apiBaseUrl = getServiceUrl();

function Patients() {
  const [patientsData, setPatientsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  async function getPatientsData() {
    const config = {
      headers: {
        'rdxtoken': localStorage.getItem('tokenId'), // Setting the token in the headers
      },
    };

    try {
      const response = await axios.get(`${apiBaseUrl}patient/patientdetails`, config);
      setPatientsData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching patient details:', error.response ? error.response.data : error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getPatientsData();
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };
    fetchData();
  }, []);

  // Preview patient details
  const previewPatient = (id) => {
    navigate(`/patients/${id}`);
  };

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          {patientsData.length > 0 ? (
            <React.Fragment>
              <h1 className="text-2xl font-semibold">Patients</h1>
              <p className="text-sm text-gray-400">Total Patients: {patientsData.length}</p>
              <div className="flex justify-end mb-4">
                <Link
                  to="/patients/create"
                  className="flex items-center bg-subMain text-white px-4 py-2 rounded-lg shadow-md hover:bg-subMain-dark transition"
                >
                  <BiPlus className="mr-2" />
                  Add Patient
                </Link>
              </div>
              <PatientTable
                data={patientsData}
                functions={{
                  preview: previewPatient,
                }}
              />
            </React.Fragment>
          ) : (
            // No Data message with an Add Patient button
            <PatientsNoDataBox
              message="No patients found."
              buttonLabel="Add Patient"
              buttonLink="/patients/create"
            />
          )}
        </React.Fragment>
      )}
    </Layout>
  );
  
}

export default Patients;

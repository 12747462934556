// src/Dashboard.js

import React, { useState, useEffect } from 'react';
import Layout from '../Layout';
import PieChart from './Charts/PieChart';
import BarChart from './Charts/BarChart';
import LineChart from './Charts/LineChart';
import DonutChart from './Charts/DonutChart';
import TimelineChart from './Charts/TimelineChart';
import axios from 'axios';
import { getServiceUrl } from '../config';
import NumberChart from './Charts/NumberChart';

const apiBaseUrl = getServiceUrl();
const charts = [
  { name: 'Total Patients', component: NumberChart, data: [] }, // Add Total Patients card
  { name: 'Diagnosed vs. Undiagnosed', component: DonutChart, data: {} },
  { name: 'Top Disease Statistics', component: BarChart, data: [] },
  { name: 'Top Phenotypes', component: BarChart, data: [] },
  { name: 'Age Distribution (in years)', component: PieChart, data: [] },
  { name: 'Gender Distribution', component: PieChart, data: [] },
  { name: 'Test Recommendations', component: PieChart, data: [] },
  { name: 'Mutation Spectrum', component: PieChart, data: [] },
  { name: 'Patient Visits Over Time', component: LineChart, data: [] },
 
  // Existing cards...
];

const Dashboard = () => {
  const [chartData, setChartData] = useState({});

  const getDashboardData = async () => {
    const config = {
      headers: {
        rdxtoken: localStorage.getItem('tokenId')
      },
    };
    try {
      const response = await axios.get(apiBaseUrl + 'doctors/getdashboarddata', config);  
      const dashboardData = Array.isArray(response.data.dashboardData) ? 
        response.data.dashboardData.reduce((acc, data) => {
          acc[data.chart_name] = data.chart_data;
          return acc;
        }, {}) : {};

      return dashboardData;

    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  function mergeData(existingData, newData) {
    for (const [key, value] of Object.entries(newData)) {
      if (existingData.hasOwnProperty(key)) {
        // Handle merging of existing keys by appending new items
        existingData[key] = [...existingData[key], ...value];
      } else {
        // Add new keys directly
        existingData[key] = value;
      }
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      const data = await getDashboardData();
     
      const dummyDataArray={
        'Diagnosed vs. Undiagnosed': [
          { value: 'diagnosed', count: 120 },
        { value: 'undiagnosed', count: 90 }
        ],
        'Top Disease Statistics': [
          { value: 'DMD Type-1', count: 120 },
          { value: 'SMA', count: 90 },
          { value: 'Pompe ', count: 80 },
          { value: 'Gaucher', count: 60 },
          { value: 'Charcot Marie Tooth', count: 50 }
        ],
        'Top Phenotypes': [
          { value: 'Muscle Weakness', count: 45 },
          { value: 'Govers Sign', count: 76 },
          { value: 'Cardio Myopathy ', count: 98 },
          { value: 'Hepatosplenomegaly', count: 20 },
          { value: 'Dysostosis', count: 56 }
        ],
       'Test Recommendations': [
          { value: 'CK Levels', count: 150 },
          { value: 'Whole Exome Sequencing', count: 80 },
          { value: 'Whole Genome Sequencing', count: 60 },
          { value: 'MLPA', count: 70 },
          { value: 'PCR', count: 90 }
        ],
        'Mutation Spectrum': [
          { value: 'CK Levels', count: 150 },
          { value: 'Whole Exome Sequencing', count: 80 },
          { value: 'Whole Genome Sequencing', count: 60 },
          { value: 'MLPA', count: 70 },
          { value: 'PCR', count: 90 }
        ],
       
        'Patient Visits Over Time': [
          { value: '2024-01-01', count: 50 },
          { value: '2024-02-01', count: 70 },
          { value: '2024-03-01', count: 65 },
          { value: '2024-04-01', count: 80 },
          { value: '2024-05-01', count: 90 }
        ]
      }
      const mergedDataArray = { ...dummyDataArray };
      if(data["Total Patients"][0].count)
      {
      mergeData(mergedDataArray, data);
      }
      setChartData(mergedDataArray)
    };

    fetchData();
  }, []);

  return (
    <Layout>
     <h1 className="text-2xl font-semibold"> Dashboard Coming Soon...</h1>
      <h4 className="text-1xl font-semibold" style={{color:'red', opacity:'0.7'}}> *Sample data representation. Please go to the patient's section </h4>
       <div className="flex flex-col mb-4" style={{ backgroundColor: 'white' }}>
      <div className="p-6 grid gap-6 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
  {charts.slice(0, 1).map((chart) => (
    <div
      key={chart.name}
      className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-2xl"
    >
      <div className="p-6 flex flex-col items-center">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">{chart.name}</h2>
        <div className="w-full">
          {chartData[chart.name] && React.createElement(chart.component, { data: chartData[chart.name] })}
        </div>
      </div>
    </div>
  ))}
</div>

        <div className="w-full p-6 grid gap-6 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
          {charts.slice(1).map((chart) => (
            <div key={chart.name} className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 hover:shadow-xl">
              <h2 className="text-xl font-semibold mb-4 text-gray-800">{chart.name}</h2>
              {chartData[chart.name] && React.createElement(chart.component, { data: chartData[chart.name] })}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePopup = ({ item, onClose, onDateChange }) => {
  const [startDate, setStartDate] = React.useState(item.startDate || null);
  const [endDate, setEndDate] = React.useState(item.endDate || null);

  const handleSave = () => {
    onDateChange(startDate, endDate);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
  <div className="bg-white rounded-lg p-6 shadow-lg max-w-md w-full relative">
    <button
      onClick={onClose}
      className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
    >
      &times;
    </button>
    <h6 className="font-semibold mb-4">Select Dates for {item.name}</h6>
    <div className="mb-4">
      <div className="flex space-x-4">
        <div className="flex-1">
          <label className="block text-gray-700">Start Date:</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="yyyy/MM/dd"
            placeholderText="Select start date"
            className="border rounded p-2 w-full"
          />
        </div>
        <div className="flex-1">
          <label className="block text-gray-700">End Date:</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat="yyyy/MM/dd"
            placeholderText="Select end date"
            className="border rounded p-2 w-full"
          />
        </div>
      </div>
    </div>

    <div className="flex justify-end">
      <button
        onClick={handleSave}
        className="bg-blue-900 text-white py-2 px-4 rounded-md"
      >
        Save
      </button>
    </div>
  </div>
</div>

  );
};

export default DatePopup;

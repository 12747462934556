import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import loginReducer from './store/reducers/login'
import { Provider } from 'react-redux';
import {thunk} from 'redux-thunk';

import { IntlProvider } from 'react-intl';
const rootReducer = combineReducers({
    login: loginReducer,
});
const logger = (store) => {
    return next => {
        return action => {
            const result = next(action);
            return result;
        }
    }
};
const store = createStore(rootReducer, applyMiddleware(logger, thunk));
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <IntlProvider locale="en">
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </IntlProvider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
import React, { useState, useEffect } from 'react';
import Layout from '../../Layout';
import { useParams } from 'react-router-dom';
import axios from 'axios'; // Import axios
import { getServiceUrl } from '../../config';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import PatientBasicDetails from './PatientBasicDetails';

const thclass = 'text-start text-sm font-medium py-3 px-4 cursor-pointer whitespace-nowrap flex items-center';
const tdclass = 'text-start text-sm py-4 px-4 whitespace-nowrap overflow-hidden text-ellipsis';

const apiBaseUrl = getServiceUrl();

export default function Tests() {
  const patientData  = localStorage.getItem("patientData")?JSON.parse(localStorage.getItem("patientData")):[]

  const { patientguid } = useParams();
  const [diseaseList, setDiseaseList] = useState([]);
  const [testList, setTestList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [patientDetails, setPatientDetails] = useState({
    name: "John Doe",
    age: 45,
    gender: "Male",
    contact: "123-456-7890",
    address: "123 Main St, Anytown, USA",
    medicalHistory: "No known allergies. Previous surgeries include appendectomy in 2015."
  }); // Dummy data

  const [selectedDisease, setSelectedDisease] = useState(null); // State to control the popup
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control the popup visibility

  const getTestDetails = async (diseaseName) => {
    const formBody = {
      condition_name: diseaseName
    };
    
    const config = {
      headers: {
        rdxtoken: localStorage.getItem('tokenId'),
      },
    };
  
    try {
      const response = await axios.post(apiBaseUrl + 'doctors/tests', formBody, config);
      console.log("response", response.data[0])
      setTestList(response.data)
      //return response.data;
    } catch (error) {
      console.error("Error fetching disease predictions:", error);
    }
  };
  
  useEffect(() => {
    const fetchDiseasePredictions = async () => {
      try {
        const config = {
          headers: {
            rdxtoken: localStorage.getItem('tokenId'),
            patientguid: patientguid
          },
        };
        const response = await axios.get(apiBaseUrl + 'doctors/predict', config);
        setDiseaseList(response.data.predictions);
        setFilteredData(response.data.predictions);
      } catch (error) {
        console.error("Error fetching disease predictions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDiseasePredictions();
  }, [patientguid]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setSortConfig({ key, direction });
    setFilteredData(sortedData);
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = diseaseList.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(query)
      )
    );

    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handleOpenPopup = (disease) => {
    setSelectedDisease(disease); // Set the clicked row data to the state
    setIsPopupOpen(true); // Open the popup
  };

  

  const handleClosePopup = () => {
    setIsPopupOpen(false); // Close the popup
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <Layout patientguid={patientguid}>
      {
       patientData?<PatientBasicDetails patientData ={ patientData }/>:null
    }
      <div className="bg-white my-8 rounded-xl border border-gray-300 shadow-md p-6">
       TEST DETAILS
      </div>
</Layout>
  );
}

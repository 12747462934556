import React, { useEffect, useState, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import Layout from '../Layout';
import axios from 'axios';
import { getServiceUrl } from '../config';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import { debounce } from 'lodash';
import './screen.css';

const apiBaseUrl = getServiceUrl();
const tdclass = 'text-start text-sm py-4 px-4 whitespace-normal overflow-hidden text-ellipsis table-cell-wrap';

function TestBooking() {
  const [diseaseData, setDiseaseData] = useState([]);
  const [selectedDisease, setSelectedDisease] = useState(null);
  const [testDetails, setTestDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(5);
  const [showModal, setShowModal] = useState(false);
  const [testToBook, setTestToBook] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const [searchQuery, setSearchQuery] = useState('');

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#ffffff' : (state.isFocused ? '#f0f0f0' : '#ffffff'),
      color: state.isSelected ? '#333333' : '#333333',
      cursor: 'pointer',
      padding: '10px 15px',
    }),
    control: (provided) => ({
      ...provided,
      borderColor: '#ccc',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#007bff',
      },
      borderRadius: '8px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#999999',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#333333',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '200px',
    }),
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const fetchDiseaseData = async (query) => {
    setLoading(true);
    try {
      const config = {
        headers: {
          'rdxtoken': localStorage.getItem('tokenId'),
        },
      };

      const response = await axios.get(`${apiBaseUrl}doctors/dropdowndiseases`, {
        ...config,
        params: {
          search: query
        }
      });
      const formattedData = response.data.map(disease => ({
        value: disease.id,
        label: disease.name,
      }));
      setDiseaseData(formattedData);
      toast.success('Data fetched successfully');
    } catch (error) {
      console.error('Error fetching disease data:', error);
      toast.error('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const fetchDiseaseDataDebounced = useMemo(() => debounce(fetchDiseaseData, 300), []);

  useEffect(() => {
    fetchDiseaseDataDebounced(searchQuery);
  }, [searchQuery]);

  const handleDiseaseSelect = async (selectedOption) => {
    setSelectedDisease(selectedOption);
    setLoading(true);
    try {
      const formBody = {
        condition_name: selectedOption.label
      };
      const config = {
        headers: {
          'rdxtoken': localStorage.getItem('tokenId'),
        },
      };
      const response = await axios.post(apiBaseUrl + 'doctors/tests', formBody, config);
      setTestDetails(response.data);
      console.log('Selected disease details:', response.data);
    } catch (error) {
      console.error('Error fetching disease details:', error);
      toast.error('Failed to fetch disease details');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const paginatedData = useMemo(() => {
    return testDetails.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  }, [testDetails, currentPage, itemsPerPage]);

  const toggleModal = () => setShowModal(!showModal);

  const handleBookTest = (test) => {
    setTestToBook(test);
    toggleModal();
  };

  const confirmBooking = () => {
    toast.success('Test booked successfully');
    setTestToBook(null);
    toggleModal();
  };

  return (
    <Layout>
      <h1 className="text-xl font-semibold mb-4">Test Booking</h1>
      <div className="bg-white my-8 rounded-xl border border-gray-300 shadow-md p-6">
        <div className="flex flex-col mb-4">
          <Select
            value={selectedDisease}
            onChange={handleDiseaseSelect}
            options={diseaseData}
            placeholder="Search by disease name"
            className="w-full mb-4"
            classNamePrefix="react-select"
            styles={customStyles}
            onInputChange={(inputValue) => setSearchQuery(inputValue)}
          />
        </div>

        {loading ? (
          <p className="text-gray-600 text-center">Loading...</p>
        ) : testDetails.length === 0 ? (
          <p className="text-gray-600 text-center">Select a disease from the dropdown to see test details here.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="table-auto w-full bg-gray-100 border border-gray-300 rounded-lg">
              <thead className="bg-blue-900 text-white rounded-t-lg">
                <tr>
                  <th className="py-3 px-4 border-b">Disease Name</th>
                  <th className="py-3 px-4 border-b">Methods</th>
                  <th className="py-3 px-4 border-b">Technology</th>
                  <th className="py-3 px-4 border-b">Action</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {paginatedData.map((detail, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className={tdclass}>{truncateText(detail.condition_identifiers, 10)}</td>
                    <td className={tdclass}>{truncateText(detail.methods, 10)}</td>
                    <td className={tdclass}>{truncateText(detail.method_categories, 10)}</td>
                    <td className={tdclass}>
                      <button
                        onClick={() => handleBookTest(detail)}
                        className="bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-200"
                      >
                        Book Test
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="my-4">
              <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={Math.ceil(testDetails.length / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>
          </div>
        )}
      </div>

      {showModal && testToBook && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out">
    <div className="bg-white p-8 rounded-xl shadow-xl w-full max-w-md mx-auto relative animate-fadeIn">
      <button
        onClick={toggleModal}
        className="absolute top-4 right-4 bg-gray-200 text-gray-600 rounded-full p-2 hover:bg-gray-300"
      >
        ✕
      </button>
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Confirm Test Booking</h2>
      <p className="text-gray-600 mb-6">
        You are about to book the following test:
      </p>
      <div className="mb-4">
        <strong className="block text-gray-800 mb-2">Test Methods:</strong>
        <p className="text-gray-700">{truncateText(testToBook.methods, 5)}</p>
      </div>
      <div className="flex justify-end gap-4">
        <button
          onClick={confirmBooking}
          className="bg-green-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-green-600 transition duration-150"
        >
          Confirm
        </button>
        <button
          onClick={toggleModal}
          className="bg-red-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-red-600 transition duration-150"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

    </Layout>
  );
}

export default TestBooking;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getServiceUrl } from '../../config';
import Loader from '../../components/CustomUI/Loader';

const apiBaseUrl = getServiceUrl();
function FamilyInfo({ patientguid, onDataUpdate }) {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    mother: {
      name: '',
      age: '',
      ageAtConception: '',
      hasDisease: 'No',
      diseaseName: ''
    },
    father: {
      name: '',
      age: '',
      hasDisease: 'No',
      diseaseName: ''
    },
    weekOfGestation: '',
    birthWeight: '',
    otherInfo: ''
  });

  const handleDataUpdateAction = async (formData) => {
    onDataUpdate({ familyInfo: formData });
  };

  useEffect(() => {
    const fetchFamilyInfo = async () => {
      try {
        const config = {
          headers: {
            rdxtoken: localStorage.getItem('tokenId'),
            "patientguid": patientguid
          },
        };
        const response = await axios.get(apiBaseUrl + 'doctors/getfamily', config);
        if(response?.data?.familyInfo!==undefined)
          {
        const familyInfo = response?.data?.familyInfo;

        setFormData({
          mother: {
            name: familyInfo.mother_name || '',
            age: familyInfo.mother_age || '',
            ageAtConception: familyInfo.mother_age_at_conception || '',
            hasDisease: familyInfo.is_mother_suffering_from_any_disease ? 'Yes' : 'No',
            diseaseName: familyInfo.mother_disease_name || ''
          },
          father: {
            name: familyInfo.father_name || '',
            age: familyInfo.father_age || '',
            hasDisease: familyInfo.is_father_suffering_from_any_disease ? 'Yes' : 'No',
            diseaseName: familyInfo.father_disease_name || ''
          },
          weekOfGestation: familyInfo.week_of_complete_gestation || '',
          birthWeight: familyInfo.patient_weight_at_birth || '',
          otherInfo: familyInfo.any_other_information || ''
        });
      }
      setLoading(false)
      } catch (error) {
        console.error("Error fetching family information:", error);
      }
    };

    fetchFamilyInfo();
  }, [patientguid]);

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    const [section, field] = name.split('.');

    setFormData((prevState) => {
      const newState = {
        ...prevState,
        [section]: {
          ...prevState[section],
          [field]: value
        }
      };

      if (!(section === 'mother' || section === 'father')) {
        newState[name] = value;
      }

      handleDataUpdateAction(newState);
      return newState;
    });
  };

  return (
    loading?<Loader/>:
    <form style={styles.container}>
    <div style={styles.row}>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Mother's Age:</label>
          <input
            type="number"
            name="mother.age"
            value={formData.mother.age}
            onChange={handleInputChange}
            style={styles.input}
            required
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Mother's Age at Conception:</label>
          <input
            type="number"
            name="mother.ageAtConception"
            value={formData.mother.ageAtConception}
            onChange={handleInputChange}
            style={styles.input}
            required
          />
        </div>
      </div>

      <div style={styles.row}>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Week of Complete Gestation:</label>
          <input
            type="number"
            name="weekOfGestation"
            value={formData.weekOfGestation}
            onChange={handleInputChange}
            style={styles.input}
            required
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Patient's Weight at Birth:</label>
          <input
            type="number"
            name="birthWeight"
            value={formData.birthWeight}
            onChange={handleInputChange}
            style={styles.input}
            required
          />
        </div>
      </div>

      <div style={styles.inputGroup}>
        <label style={styles.label}>Does Mother Have a Disease?</label>
        <select
          name="mother.hasDisease"
          value={formData.mother.hasDisease}
          onChange={handleInputChange}
          style={styles.input}
          required
        >
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
      </div>

      {formData.mother.hasDisease === 'Yes' && (
        <div style={styles.inputGroup}>
          <label style={styles.label}>Disease Name and Information:</label>
          <input
            type="text"
            name="mother.diseaseName"
            value={formData.mother.diseaseName}
            onChange={handleInputChange}
            style={styles.input}
          />
        </div>
      )}

      <div style={styles.row}>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Father's Age:</label>
          <input
            type="number"
            name="father.age"
            value={formData.father.age}
            onChange={handleInputChange}
            style={styles.input}
            required
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Does Father Have a Disease?</label>
          <select
            name="father.hasDisease"
            value={formData.father.hasDisease}
            onChange={handleInputChange}
            style={styles.input}
            required
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </div>
      </div>

      {formData.father.hasDisease === 'Yes' && (
        <div style={styles.inputGroup}>
          <label style={styles.label}>Disease Name and Information:</label>
          <input
            type="text"
            name="father.diseaseName"
            value={formData.father.diseaseName}
            onChange={handleInputChange}
            style={styles.input}
          />
        </div>
      )}

      <div style={styles.inputGroup}>
        <label style={styles.label}>Any Other Information:</label>
        <textarea
          name="otherInfo"
          value={formData.otherInfo}
          onChange={handleInputChange}
          style={styles.input}
        />
      </div>
    </form>
  );
}

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
  },
  header: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
  },
  inputGroup: {
    flex: 1,
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#555',
  },
  input: {
    width: '100%',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
};

export default FamilyInfo;

import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import '@fortawesome/fontawesome-free/css/all.min.css';

function index({ children, title, patientguid, diseaseName }) {
 return (
    <div className="bg-dry xl:h-screen flex-colo ">
      <div className="grid xl:grid-cols-12 w-full 2xl:max-w-[2000px]">
        <div className="col-span-2 xl:block hidden">
          <Sidebar patientguid={patientguid} diseaseName={diseaseName} />
        </div>
        <div className="col-span-10 xl:h-screen overflow-y-scroll relative">
          <Header title={title} />
          <div className="xs:px-8 px-2" style={{paddingTop:'4.5rem'}}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default index;

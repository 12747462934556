import React from 'react';

function Summary({ patientData }) {
  if (!patientData) {
    return <div className="p-4 text-center text-gray-700">No patient data available.</div>;
  }

  const {
    firstname,
    lastname,
    age,
    dob,
    gender,
    mother_age,
    mother_age_at_conception,
    week_of_complete_gestation,
    patient_weight_at_birth,
    mother_disease_name,
    father_age,
    father_disease_name,
    any_other_information
  } = patientData;

  return (
    <div className="p-6 bg-white shadow-md rounded-lg border border-gray-200">
      <h2 className="text-lg font-semibold mb-4">Patient Summary</h2>
      <div className="space-y-4">
        {firstname && lastname && <p><strong>Name:</strong> {firstname} {lastname}</p>}
        {age && <p><strong>Age:</strong> {age}</p>}
        {dob && <p><strong>Date of Birth:</strong> {dob}</p>}
        {gender && <p><strong>Gender:</strong> {gender}</p>}
        {mother_age && <p><strong>Mother's Age:</strong> {mother_age}</p>}
        {mother_age_at_conception && <p><strong>Mother's Age At Conception:</strong> {mother_age_at_conception}</p>}
        {week_of_complete_gestation && <p><strong>Week of Complete Gestation:</strong> {week_of_complete_gestation}</p>}
        {patient_weight_at_birth && <p><strong>Patient's Weight at Birth:</strong> {patient_weight_at_birth}</p>}
        {mother_disease_name && <p><strong>Mother's Disease Name and Information:</strong> {mother_disease_name}</p>}
        {father_age && <p><strong>Father's Age:</strong> {father_age}</p>}
        {father_disease_name && <p><strong>Father's Disease Name and Information:</strong> {father_disease_name}</p>}
        {any_other_information && <p><strong>Additional Information:</strong> {any_other_information}</p>}
      </div>
    </div>
  );
}

export default Summary;

// src/components/Charts/LineChart.js

import React from 'react';
import Chart from 'react-apexcharts';

const LineChart = ({ data }) => {
  const chartOptions = {
    chart: {
      type: 'line',
      height: 250,
    },
    xaxis: {
      categories: data.map(item => item.value),
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    colors:['#123569', '#053B96', '#F69600', '#3BB27B']
  };

  const chartSeries = [
    {
      name: 'Visits',
      data: data.map(item => item.count),
    },
  ];

  return (
    <Chart
      options={chartOptions}
      series={chartSeries}
      type="line"
      width="100%"
    />
  );
};

export default LineChart;

import './App.css';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './screens/Dashboard';
import Patients from './screens/Patients/Patients';
import PatientProfile from './screens/Patients/PatientProfile';
import CreatePatient from './screens/Patients/CreatePatient';
import NotFound from './screens/NotFound';
import Login from './screens/Login';
import Diseases from './screens/Patients/Diseases'
import DiseaseDetails from './screens/Patients/DiseaseDetails'
import TestBooking from './screens/TestBooking'
import Genotype from './screens/Patients/Genotype'
import Tests from './screens/Patients/Tests'
import PatientDashboard from './screens/Patients/Dashboard'
import { PrivateRoute } from './components/CustomRoutes/PrivateRoute';
import { PublicRoute } from './components/CustomRoutes/PublicRoute';


const App = () => {
  return (   
    <Routes>
      <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
      {/* patients */}
      <Route path="/patients" element={<PrivateRoute element={Patients} />} />
      <Route path="/patients/:patientguid" element={<PrivateRoute element={PatientProfile} />} />
      <Route path="/patients/create" element={<PrivateRoute element={CreatePatient} />} />
      <Route path="/patients/diseases/:patientguid" element={<PrivateRoute element={Diseases} />} />
      <Route path="/patients/diseasedetails/:patientguid/:diseaseName" element={<PrivateRoute element={DiseaseDetails} />} />  
      <Route path="/testbooking" element={<PrivateRoute element={TestBooking} />} />
      <Route path="/patients/genotype/:patientguid" element={<PrivateRoute element={Genotype} />} />
      <Route path="/patients/tests/:patientguid" element={<PrivateRoute element={Tests} />} />
      <Route path="/patients/dashboard/:patientguid" element={<PrivateRoute element={PatientDashboard} />} />

      {/* others */}
      <Route path="/login" element={<PublicRoute element={Login} />} />
      <Route path="/" element={<PublicRoute element={Login} />} />
    
      <Route path="*" element={<NotFound />} />
    </Routes>   
  );
};

export default App;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from '../components/Form';
import { BiFontSize, BiLogInCircle } from 'react-icons/bi';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { connect } from 'react-redux';
import * as actionCreators from '../store/actions/index';
import SignUp from './Signup'; // Ensure this import path matches
import Loader from '../components/CustomUI/Loader';

const Login = (props) => {
  const [formState, setFormState] = useState({
    formIsValid: false,
    username: '',
    password: '',
    passwordError: '',
    showPasswordError: false,
  });

  const [showSignUp, setShowSignUp] = useState(false); // State for popup visibility
  const [loading, setLoading] = useState(false); // State for loading
  const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility

  const navigate = useNavigate();

  const inputChangedHandler = (event, field) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const formData = {
      email: formState.username,
      password: formState.password,
    };

    if (formState.password) {
      props.onAuth(formData, navigate);
    } else {
      setFormState((prevState) => ({
        ...prevState,
        passwordError: 'Please enter the password',
        showPasswordError: true,
      }));
    }
  };

  return (
    loading ? <Loader /> :
    <div className="w-full h-screen flex bg-white" style={{padding:60}}>
  {/* Left Section: Login Panel (40%) */}
  <div className="w-3/6 h-full flex justify-center items-center">
    <div className="w-full max-w-md p-10 bg-blue-50 rounded-lg shadow-lg">
      <form className="w-full flex-colo">
        <img src="/images/rdxlogo.png" alt="logo" className="w-48 h-16 object-contain mb-6" />
        <div className="flex flex-col gap-6 w-full mb-6">
          <Input
            label="Email"
            color={true}
            className="border border-blue-200 rounded-lg p-3 text-gray-800"
            placeholder="doctor@example.com"
            value={formState.username}
            onChange={(event) => inputChangedHandler(event, 'username')}
          />
          <div className="relative w-full">
            <Input
              label="Password"
              type={passwordVisible ? 'text' : 'password'}
              color={true}
              className="border border-blue-200 rounded-lg p-3 text-gray-800"
              placeholder="*********"
              value={formState.password}
              onChange={(event) => inputChangedHandler(event, 'password')}
            />
            <div
              className="absolute right-4 top-12 cursor-pointer text-gray-500"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? <AiFillEye size={24} /> : <AiFillEyeInvisible size={24} />}
            </div>
          </div>
          {formState.showPasswordError && (
            <div className="text-red-500 text-sm">{formState.passwordError}</div>
          )}
        </div>
        <Button
          label="Login"
          Icon={BiLogInCircle}
          className="bg-blue-600 text-white hover:bg-blue-700 focus:ring-2 focus:ring-blue-500"
          onClick={(event) => submitHandler(event)}
        />
        <div className="mt-4 text-left">
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() => setShowSignUp(true)}
          >
            New here? Create an account
          </span>
        </div>
      </form>

      {showSignUp && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <SignUp onClose={() => setShowSignUp(false)} />
        </div>
      )}
    </div>
  </div>

  {/* Right Section: Vision and Tagline (60%) */}
  <div className="w-3/6 h-full flex flex-col justify-start items-start bg-white-100" style={{paddingTop:140}}>
    <h1 className="text-5xl font-bold text-gray-800 mb-8">
     <p style={{textAlign:'center', fontSize:45}}>Your Partner in</p>
     <p className='text-blue-900'>Rare Disease Diagnosis!</p>
    </h1>
   <p className="text-lg text-gray-700" style={{textAlign:'center', width:'100%', marginTop:-10}}>
      We provide cutting-edge technology and expertise to assist in diagnosing
      rare diseases with precision and care. Trust us to be your guide on the
      journey to better health.
    </p>
    <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
  <img src="/images/logo.png" alt="logo" className="w-36 h-12 object-contain mb-6" />
</span>


 </div>
</div>

  );
};

const mapStateToProps = (state) => ({
  loading: state.login.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onAuth: (formData, navigate) => dispatch(actionCreators.auth(formData, navigate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login)